import { Diary, Meta, TestCategory, User } from 'model'
import { api } from './base.service'

type UserResult = {
  data: User[]
  meta: Meta
}

type DiaryResult = {
  data: Diary[]
  meta: Meta
}

class UserSerivce {
  async concernUser(id: number) {
    return await api.post(`/users/${id}/concern`, null)
  }

  async getTotalCount() {
    return await api.get<number>('/users/count')
  }

  async getInterestCount() {
    return await api.get<number>('/users/concern/count')
  }

  async getInterests({
    limit,
    page,
    search,
  }: {
    limit: number
    page: number
    search: string
  }) {
    return await api.get<UserResult>(
      `/users/concern?limit=${limit}&page=${page}&search=${search}`,
    )
  }

  async getInterestDetail(id: number) {
    return await api.get<User>(`/users/concern/${id}`)
  }

  async getTests(id: number) {
    return await api.get<TestCategory[]>(`/users/${id}/test`)
  }

  async getDiaries({
    id,
    limit,
    page,
  }: {
    id: number
    limit: number
    page: number
  }) {
    return await api.get<DiaryResult>(
      `/users/${id}/diaries?limit=${limit}&page=${page}`,
    )
  }
}

export default new UserSerivce()
