import { Box, Input, Text } from '@chakra-ui/react'

type InputBoxProp = {
  label: string
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  type?: string
  isValid?: boolean
  error?: string
}

export function InputBox({
  label,
  value,
  setValue,
  type,
  isValid,
  error,
}: InputBoxProp) {
  return (
    <Box w="350px">
      <Text as="b" mb={2} display="block" fontSize="1.2rem">
        {label}
      </Text>
      <Input
        placeholder={label}
        value={value}
        onChange={(e) => {
          e.preventDefault()
          setValue(e.target.value)
        }}
        bg="white"
        type={type}
      />
      <Text color="red" display="block" fontSize="sm">
        {isValid ? '' : error}
      </Text>
    </Box>
  )
}
