export type Location = {
  id: number
  name: string
  createdAt: string
}

export const City = {
  광역자치단체: [
    '서울특별시',
    '부산광역시',
    '대구광역시',
    '인천광역시',
    '대전광역시',
    '광주광역시',
    '울산광역시',
    '세종특별자치시',
  ],
  경기도: [
    '수원시',
    '성남시',
    '의정부시',
    '안양시',
    '부천시',
    '광명시',
    '평택시',
    '동두천시',
    '안산시',
    '고양시',
    '과천시',
    '구리시',
    '남양주시',
    '오산시',
    '시흥시',
    '군포시',
    '의왕시',
    '하남시',
    '용인시',
    '파주시',
    '이천시',
    '안성시',
    '김포시',
    '화성시',
    '광주시',
    '양주시',
    '포천시',
    '여주시',
  ],
  강원특별자치도: [
    '춘천시',
    '원주시',
    '강릉시',
    '동해시',
    '태백시',
    '속초시',
    '삼척시',
  ],
  충청북도: ['청주시', '충주시', '제천시'],
  충청남도: [
    '천안시',
    '공주시',
    '보령시',
    '아산시',
    '서산시',
    '논산시',
    '계룡시',
    '당진시',
  ],
  전라북도: ['전주시', '군산시', '익산시', '정읍시', '남원시', '김제시'],
  전라남도: ['목포시', '여수시', '순천시', '나주시', '광양시'],
  경상북도: [
    '포항시',
    '경주시',
    '김천시',
    '안동시',
    '구미시',
    '영주시',
    '영천시',
    '상주시',
    '문경시',
    '경산시',
  ],
  경상남도: [
    '창원시',
    '진주시',
    '통영시',
    '사천시',
    '김해시',
    '밀양시',
    '거제시',
    '양산시',
  ],
  제주특별자치도: ['제주시', '서귀포시'],
}
