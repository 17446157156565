import { VStack, Link, Box, StackDivider, Text } from '@chakra-ui/react'
import { Link as RLink, useLocation, useNavigate } from 'react-router-dom'
import { AuthService } from 'service'

export function Sidebar() {
  const navigate = useNavigate()
  const location = useLocation()
  const isActive = (path: string) => {
    return path === location.pathname
  }

  return (
    <Box h="100vh" boxShadow="lg" w="200px">
      <VStack
        w="200px"
        py={4}
        spacing={4}
        divider={<StackDivider borderColor="gray.200" />}
      >
        <Link
          as={RLink}
          to="/"
          sx={{ fontWeight: isActive('/') ? 'bold' : 'normal' }}
        >
          홈
        </Link>
        <Link
          as={RLink}
          to="/diary"
          sx={{ fontWeight: isActive('/diary') ? 'bold' : 'normal' }}
        >
          마음일기 관리
        </Link>
        <Link
          as={RLink}
          to="/interest"
          sx={{ fontWeight: isActive('/interest') ? 'bold' : 'normal' }}
        >
          관심인원 관리
        </Link>
        <Link
          as={RLink}
          to="/notice"
          sx={{ fontWeight: isActive('/notice') ? 'bold' : 'normal' }}
        >
          통합 알림 설정
        </Link>
        <Link
          as={RLink}
          to="/test"
          sx={{ fontWeight: isActive('/test') ? 'bold' : 'normal' }}
        >
          프롬프트 테스트
        </Link>
        <Link
          as={RLink}
          to="/coupon"
          sx={{ fontWeight: isActive('/coupon') ? 'bold' : 'normal' }}
        >
          이용권 관리
        </Link>
        <Link
          as={RLink}
          to="/location"
          sx={{ fontWeight: isActive('/location') ? 'bold' : 'normal' }}
        >
          시(행정구역) 관리
        </Link>
        <Link
          as={RLink}
          to="/setting"
          sx={{ fontWeight: isActive('/setting') ? 'bold' : 'normal' }}
        >
          설정
        </Link>
      </VStack>
      <Text
        textAlign="center"
        position="absolute"
        bottom={12}
        w="200px"
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={async () => {
          await AuthService.signout()
          navigate('/login')
        }}
      >
        로그아웃
      </Text>
    </Box>
  )
}
