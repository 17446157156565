import { Box, Flex, Icon, Text, useToast } from '@chakra-ui/react'
import { ToastTheme } from 'component/common'
import dayjs from 'dayjs'
import { Diary } from 'model'
import { FaCircle, FaRegStar, FaStar } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export function DiaryItem({ item }: { item: Diary }) {
  const navigate = useNavigate()
  const toast = useToast()
  const goDiary = (id: number) => {
    if (item.isAnswering) {
      toast({ description: '답변중인 일기입니다.', ...ToastTheme.error })
      return
    }
    navigate(`/diary/${id}`)
  }
  return (
    <Box
      borderWidth="1px"
      borderColor="gray.100"
      p={4}
      borderRadius="md"
      cursor="pointer"
      onClick={() => {
        goDiary(item.id)
      }}
    >
      <Flex align="center" justify="space-between" fontSize="0.9rem" mb={1}>
        <Text color={item.isAnswering ? 'yellow' : 'green'}>
          <Icon as={FaCircle} /> {item.isAnswering ? '작성 중' : '작성 가능'}
        </Text>
        <Text>{dayjs(item.createdAt).format('M월 D일')}</Text>
      </Flex>
      <Flex flexDirection="row" align="center">
        {item.user.isConcerned ? <FaStar /> : <FaRegStar />}
        <Text>{item.user.nick}의 일기</Text>
      </Flex>
    </Box>
  )
}
