import { Grid, GridItem, useToast } from '@chakra-ui/react'
import { Layout, Prompt, PromptResult, ToastTheme } from 'component'
import { AutoAnswerParam, ResponseChoice } from 'model'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { PromptTestSerivce } from 'service'

export default function TestScreen() {
  const toast = useToast()
  const [resultList, setResultList] = useState<ResponseChoice[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const createTestResult = useMutation(
    ['createTestResult'],
    async (param: AutoAnswerParam) => {
      setIsLoading(true)
      return await PromptTestSerivce.createResults({ ...param })
    },
    {
      onSuccess: (data) => {
        setIsLoading(false)
        setResultList(data)
        toast({ description: '답변이 생성되었습니다.', ...ToastTheme.success })
      },
      onError: () => {
        setIsLoading(false)
        toast({
          description: '답변 생성에 실패하였습니다',
          ...ToastTheme.error,
        })
      },
    },
  )
  return (
    <Layout title="테스트">
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem w="100%" h="700px">
          <Prompt
            setParam={(param) => {
              createTestResult.mutate(param)
            }}
          />
        </GridItem>
        <GridItem w="100%" h="700px">
          <PromptResult loading={isLoading} resultList={resultList} />
        </GridItem>
      </Grid>
    </Layout>
  )
}
