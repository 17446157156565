import { Text, Divider } from '@chakra-ui/react'
import { Section } from 'component'
import { Diary } from 'model'

export function DiaryContent({ diary }: { diary: Diary }) {
  return (
    <Section title="마음일기" sx={{ p: '6' }}>
      <Text as="b">나의 기분</Text>
      <Text>{diary.emotion}</Text>
      <Divider borderColor="gray.200" my={4} />
      <Text as="b">나의 사건</Text>
      <Text>{diary.event}</Text>
      <Divider borderColor="gray.200" my={4} />
      <Text as="b">나의 생각</Text>
      <Text>{diary.free}</Text>
      <Divider borderColor="gray.200" my={4} />
      <Text as="b">나의 이야기</Text>
      <Text>{diary.think}</Text>
    </Section>
  )
}
