import {
  Flex,
  Text,
  Box,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react'
import { Layout, ToastTheme } from 'component'
import dayjs from 'dayjs'
import { Diary, FilterOption, SortOption } from 'model'
import { useQuery } from 'react-query'
import { DiaryService } from 'service'
import {
  FaRegStar,
  FaStar,
  FaCircle,
  FaFilter,
  FaUndoAlt,
  FaRegClock,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Pagination } from 'component/common/Pagination'
import { DiaryHeader } from 'component/diary'
import utc from 'dayjs/plugin/utc'
import { theme } from 'index'
import { useRecoilState } from 'recoil'
import { diaryPage } from 'store'
dayjs.extend(utc)

export default function DiaryScreen() {
  const toast = useToast()
  const navigate = useNavigate()
  const [diaryList, setDiaryList] = useState<Diary[]>([])
  const [total, setTotal] = useState<number>(-1)
  const [_page, setRecoilPage] = useRecoilState(diaryPage)

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<FilterOption>('all')
  const [sort, setSort] = useState<SortOption>('latest')
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPage] = useState<number>(1)

  useEffect(() => {
    if (_page) {
      setPage(_page)
    }
  }, [])

  const allDiary = useQuery(
    ['getAllDiary', page, search, filter, sort],
    async () => {
      return await DiaryService.get({ limit: 10, page, search, filter, sort })
    },
    {
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
      onSuccess: (data) => {
        setDiaryList(data.data)
        setTotal(data.meta.total)
        setPage(data.meta.currentPage)
        setRecoilPage(data.meta.currentPage)
        setTotalPage(data.meta.totalPages)
      },
    },
  )
  const enterSearch = (searchWord: string) => {
    setSearch(searchWord)
    setFilter('all')
    setSort('latest')
  }

  return (
    <Layout title="마음일기 관리">
      <DiaryHeader setSearch={enterSearch} />
      <Flex justify="space-between">
        <IconButton
          bg="transparent"
          icon={<FaUndoAlt />}
          aria-label="reload"
          onClick={() => {
            allDiary.refetch()
          }}
        />
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              bg="transparent"
              icon={<FaFilter />}
              aria-label="filter btn"
              mr={2}
            ></MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setFilter('all')
                  setPage(1)
                  setRecoilPage(1)
                }}
              >
                모든 일기
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilter('waiting')
                  setPage(1)
                  setRecoilPage(1)
                }}
              >
                답변 대기 일기
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilter('concern')
                  setPage(1)
                  setRecoilPage(1)
                }}
              >
                관심 인원 일기
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              as={IconButton}
              bg="transparent"
              icon={<FaRegClock />}
              aria-label="sort btn"
              mr={2}
            ></MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setSort('latest')
                  setPage(1)
                  setRecoilPage(1)
                }}
              >
                시간순으로 보기
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSort('name')
                  setPage(1)
                  setRecoilPage(1)
                }}
              >
                이름순으로 보기
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      {diaryList.length === 0 && (
        <Box as="section">
          <Text>마음일기가 없습니다.</Text>
        </Box>
      )}
      {diaryList.length > 0 && (
        <Box as="section">
          <TableContainer>
            <Table>
              <Tbody>
                {diaryList.map((diary: Diary, idx: number) => {
                  return (
                    <Tr
                      key={`diary ${diary.id}`}
                      _hover={{ bg: '#eaeaea' }}
                      cursor="pointer"
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={async () => {
                        const _diary = await DiaryService.getDetail(diary.id)
                        if (_diary.isAnswering) {
                          toast({
                            description: '답변중인 일기입니다.',
                            ...ToastTheme.error,
                          })
                          return
                        }
                        navigate(`/diary/${diary.id}`)
                      }}
                    >
                      <Td>
                        {!diary.isAnswering ? (
                          diary.answer ? (
                            <FaCircle color="green" />
                          ) : (
                            <FaCircle color="red" />
                          )
                        ) : (
                          <FaCircle color={theme.colors.yellow} />
                        )}
                      </Td>
                      <Td>
                        {diary.user.isConcerned ? <FaStar /> : <FaRegStar />}
                      </Td>
                      <Td>{diary.user.nick}</Td>
                      <Td>{diary.emotion.slice(0, 30)}</Td>
                      <Td>{dayjs(diary.createdAt).utc().format('M월 D일')}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            current={page}
            total={total}
            perPage={10}
            totalPages={totalPages}
            setPage={(p) => {
              setPage(p)
              setRecoilPage(p)
            }}
          />
        </Box>
      )}
    </Layout>
  )
}
