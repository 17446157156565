import {
  Box,
  Button,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { Layout, Pagination, Section, ToastTheme } from 'component'
import { City, Location } from 'model'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import LocationService from 'service/location.service'
import dayjs from 'dayjs'
import { AxiosError } from 'axios'

type Data = {
  message: string[]
  statusCode: number
}

export default function LocationScreen() {
  const toast = useToast()
  const [locationList, setLocationList] = useState<Location[]>([])
  const [location, setLocation] = useState<boolean>(true)
  const [currentLocation, setCurrentLocation] = useState<string>('')
  const [city, setCity] = useState<keyof typeof City>('광역자치단체')
  const [total, setTotal] = useState<number>(-1)
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPage] = useState<number>(1)
  const [name, setName] = useState<string>('서울특별시')

  useQuery(
    ['getLocations', page],
    async () => await LocationService.get({ limit: 5, page }),
    {
      enabled: location,
      onSuccess: (data) => {
        setLocationList(data.data)
        setTotal(data.meta.total)
        setPage(data.meta.currentPage)
        setTotalPage(data.meta.totalPages)
        setLocation(false)
        setCurrentLocation(data.data[0].name)
      },
    },
  )

  const createLocation = useMutation(
    ['create location'],
    async () =>
      await LocationService.create({
        name,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '시(행정구역)이 생성되었습니다!',
            ...ToastTheme.success,
          })
          setLocation(true)
        }
      },
      onError: (error: AxiosError) => {
        const message = (error.response?.data as Data).message
        toast({
          description: message,
          ...ToastTheme.error,
        })
      },
    },
  )

  return (
    <Layout title="시(행정구역) 관리">
      <Section title="시(행정구역) 생성" sx={{ p: '10', mb: 6 }}>
        <Text>시 / 도</Text>
        <Select
          mt={3}
          w="150px"
          onChange={(e) => {
            setCity(e.target.value as keyof typeof City)
          }}
        >
          {Object.keys(City).map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </Select>
        <Text mt={3}>시(행정구역)</Text>
        <Select
          mt={3}
          w="150px"
          onChange={(e) => {
            setName(e.target.value)
          }}
        >
          {City[city].map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </Select>
        <Button
          mt={6}
          onClick={() => {
            createLocation.mutate()
          }}
        >
          생성
        </Button>
      </Section>
      <Section title="설정된 시(행정구역)" sx={{ p: '10', mb: 6 }}>
        <Box
          w="150px"
          textAlign="center"
          bgColor="gray.100"
          fontWeight="bold"
          p={3}
          borderRadius="8px"
        >
          {currentLocation}
        </Box>
      </Section>
      <Section title="시(행정구역) 기록" sx={{ p: 10 }}>
        <Table>
          <Thead>
            <Tr>
              <Td>시(행정구역)</Td>
              <Td>생성일</Td>
            </Tr>
          </Thead>
          <Tbody>
            {locationList.length === 0 && (
              <Tr>
                <Td>시(행정구역)을 등록해주세요.</Td>
              </Tr>
            )}
            {locationList.length > 0 &&
              locationList.map((location: Location) => {
                return (
                  <Tr key={`location ${location.id}`}>
                    <Td>{location.name}</Td>

                    <Td>
                      {dayjs(location.createdAt).utc().format('YYYY년 M월 D일')}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
        <Pagination
          current={page}
          total={total}
          perPage={5}
          totalPages={totalPages}
          setPage={(p) => {
            setPage(p)
            setLocation(true)
          }}
        />
      </Section>
    </Layout>
  )
}
