import { CookieKey } from 'constant'
import dayjs from 'dayjs'
import { removeCookie, setCookie } from 'helpers'
import { Admin } from 'model'

import { api } from './base.service'

class AuthService {
  async login(name: string, password: string) {
    const token: { access: string } = await api.post('/auth/login', {
      name,
      password,
    })
    setCookie(CookieKey.ACCESS_TOKEN, token.access, {
      expires: dayjs().add(1, 'year').toDate(),
    })
  }

  async signout() {
    removeCookie(CookieKey.ACCESS_TOKEN)
  }

  async getAdmin() {
    return await api.get<Admin>('/auth')
  }

  async changPassword({
    password,
    newPassword,
  }: {
    password: string
    newPassword: string
  }) {
    return await api.post<boolean>('/auth/password', { password, newPassword })
  }
}

export default new AuthService()
