import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({ storage: sessionStorage })

export const wrAnswer = atom<{ id: number | undefined; content: string }>({
  key: 'wrAnswer',
  default: { id: undefined, content: '' },
  effects_UNSTABLE: [persistAtom],
})
