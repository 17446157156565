import { Button, HStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

const activeStyle = {
  bg: 'darkblue',
  color: 'white',
}

export function Pagination({
  current,
  total,
  perPage,
  totalPages,
  setPage,
}: {
  current: number
  total: number
  perPage: number
  totalPages: number
  setPage: (arg: number) => void
}) {
  const [currentPage, setCurrentPage] = useState(current)
  const [pages, setPages] = useState<number[]>([])

  useEffect(() => {
    setCurrentPage(current)

    const _current = current - 1
    const i = (_current - (_current % 10)) / 10
    if (!pages.includes(current)) {
      let newPages: number[] = []
      if (totalPages >= (i + 1) * 10) {
        newPages = Array.from({ length: 10 }, (_, v: number) => v + i * 10 + 1)
      } else {
        newPages = Array.from(
          { length: totalPages - i * 10 },
          (_, v) => v + i * 10 + 1,
        )
      }
      setPages(newPages)
    } else {
      if (totalPages >= (i + 1) * 10) {
        setPages(Array.from({ length: 10 }, (v, idx) => i * 10 + idx + 1))
      } else {
        setPages(
          Array.from(
            { length: totalPages - i * 10 },
            (v, idx) => i * 10 + idx + 1,
          ),
        )
      }
    }
  }, [total, current])

  return (
    <HStack gap={0.5} justify="center" mt={2}>
      <Button
        isDisabled={currentPage < 10}
        onClick={() => {
          const i = (currentPage - (currentPage % 10)) / 10
          setCurrentPage(i * 10)
          setPage(i * 10)
        }}
      >
        <FaAngleLeft />
      </Button>
      {pages.map((p: number) => {
        return (
          <Button
            key={`${currentPage} / ${p}`}
            onClick={() => {
              setCurrentPage(p)
              setPage(p)
            }}
            sx={p === currentPage ? { ...activeStyle } : {}}
          >
            {p}
          </Button>
        )
      })}
      <Button
        isDisabled={
          currentPage <= totalPages &&
          currentPage >= totalPages - (totalPages % 10) + 1
        }
        onClick={() => {
          const _current = currentPage - 1
          const i = (_current - (_current % 10)) / 10
          setCurrentPage((i + 1) * 10 + 1)
          setPage((i + 1) * 10 + 1)
        }}
      >
        <FaAngleRight />
      </Button>
    </HStack>
  )
}
