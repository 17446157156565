import {
  Flex,
  Text,
  useToast,
  VStack,
  Divider,
  Textarea,
  Button,
} from '@chakra-ui/react'
import { ToastTheme } from 'component/common'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AutoAnswerService, DiaryService } from 'service'
import { AutoAnswerBox } from './AutoAnswerBox'
import { AutoAnswer } from 'model'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { wrAnswer } from 'store'

const isActiveStyle = {
  color: 'blue',
  textDecoration: 'underline',
  textUnderlineOffset: '3px',
  textDecorationThickness: '2px',
}

export function AutoAnswerContainer({
  loading,
  initAnswer,
  writingAnswer,
}: {
  loading: boolean
  initAnswer: string
  writingAnswer: string
}) {
  const toast = useToast()
  const { id } = useParams()

  const [disabled, setDisabled] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<string>('total')
  const [answer, setAnswer] = useState<string>('')
  const setWrAnswer = useSetRecoilState(wrAnswer)
  const [auto, setAuto] = useState<boolean>(true)
  const [like, setLike] = useState<boolean>(false)
  const [autoAnswers, setAutoAnswers] = useState<AutoAnswer[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (initAnswer) {
      setDisabled(true)
    }
    setAnswer(initAnswer)
    setIsLoading(loading)
    if (writingAnswer) {
      setAnswer(writingAnswer)
      setWrAnswer({ id: Number(id), content: writingAnswer })
    }
    if (!loading) {
      getAutoAnswers.refetch()
    }
  }, [initAnswer, loading, writingAnswer])

  const getAutoAnswers = useQuery(
    ['getAutoAnswers', id],
    async () => {
      return await AutoAnswerService.getAnswers(Number(id))
    },
    {
      enabled: auto,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAutoAnswers(data)
        setAuto(false)
      },
    },
  )

  useQuery(
    ['getlikeAutoAnswers', id],
    async () => {
      return await AutoAnswerService.getlikeAnswers(Number(id))
    },
    {
      enabled: like,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAutoAnswers(data)
        setLike(false)
      },
    },
  )

  const createAnswer = useMutation(
    ['createAnswer'],
    async () => {
      return await DiaryService.createAnswer(Number(id), answer)
    },
    {
      onSuccess: () => {
        toast({ description: '답변이 등록되었습니다.', ...ToastTheme.success })
      },
      onError: () => {
        toast({
          description: '답변 등록에 실패하였습니다',
          ...ToastTheme.error,
        })
      },
    },
  )

  return (
    <VStack w="70%" gap={2} align="stretch">
      <Flex gap={4}>
        <Text
          as="b"
          cursor="pointer"
          onClick={() => {
            setIsActive('total')
            setAuto(true)
          }}
          sx={isActive === 'total' ? { ...isActiveStyle } : undefined}
        >
          전체
        </Text>
        <Text
          as="b"
          cursor="pointer"
          onClick={() => {
            setIsActive('like')
            setLike(true)
          }}
          sx={isActive === 'like' ? { ...isActiveStyle } : undefined}
        >
          좋아요
        </Text>
      </Flex>
      {isLoading ? (
        <Text w="100%" textAlign="center">
          생성 중...
        </Text>
      ) : (
        autoAnswers?.map((ans) => {
          return (
            <AutoAnswerBox
              key={ans.id}
              answer={ans}
              refetch={() => {
                if (isActive === 'total') {
                  setAuto(true)
                } else {
                  setLike(true)
                }
              }}
              copy={(answer) => {
                setAnswer(answer)
              }}
            />
          )
        })
      )}
      <Divider />
      <Flex justify="space-between" m={2}>
        <Text as="b">답변하기 ({answer.length} / 500)</Text>
        <Text
          as="button"
          disabled={initAnswer !== ''}
          cursor="pointer"
          onClick={() => {
            setAnswer('')
          }}
        >
          전체 지우기
        </Text>
      </Flex>
      <Textarea
        resize="none"
        bg="white"
        value={answer}
        minH="200px"
        disabled={initAnswer !== ''}
        onChange={(e) => {
          e.preventDefault()
          if (e.target.value.length > 500) {
            return
          }
          setWrAnswer({ id: Number(id), content: e.target.value })
          setAnswer(e.target.value)
        }}
      />
      <Button
        mt={2}
        isDisabled={disabled}
        onClick={() => {
          createAnswer.mutate()
        }}
      >
        답변하기
      </Button>
    </VStack>
  )
}
