import { Box, Divider } from '@chakra-ui/react'
import { Layout, InterestDetail, InterestHeader, TestCard } from 'component'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { UserService } from 'service'

export default function InterestDetailScreen() {
  const { id } = useParams()

  const { isLoading, data, refetch } = useQuery(
    ['getInterestDetail', id],
    async () => {
      return await UserService.getInterestDetail(Number(id))
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return (
    <Layout title="관심인원 관리">
      <InterestHeader />
      {!isLoading && data && (
        <Box>
          <InterestDetail.User
            user={data}
            refetch={() => {
              refetch()
            }}
          />
          <TestCard id={data.id} />
          <Divider borderColor="gray.300" my={6} />
          <InterestDetail.Content />
        </Box>
      )}
    </Layout>
  )
}
