import { Meta, Prompt } from 'model'
import { api } from '.'

type Result = {
  data: Prompt[]
  meta: Meta
}

class PromptService {
  async getAll({ limit, page = 1 }: { limit: number; page?: number }) {
    return await api.get<Result>(`/prompt?limit=${limit}&page=${page}`)
  }

  async get(id: number) {
    return await api.get<Prompt>(`/prompt/${id}`)
  }

  async getDefault() {
    return await api.get<Prompt>('/prompt/basic')
  }

  async create(
    input: Pick<Prompt, 'name' | 'maxTokens' | 'prompt' | 'temperature'>,
  ) {
    return await api.post<boolean>('/prompt', input)
  }

  async edit(
    id: number,
    input: Pick<Prompt, 'name' | 'maxTokens' | 'prompt' | 'temperature'>,
  ) {
    return await api.patch<boolean>(`/prompt/${id}`, input)
  }

  async setDefault(id: number) {
    return await api.patch<boolean>(`/prompt/${id}/basic`)
  }

  async delete(id: number) {
    return await api.delete<boolean>(`/prompt/${id}`)
  }
}
export default new PromptService()
