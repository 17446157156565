type Analyze = Record<string, any>
type Color = Record<string, string>

export const testResult = (test: string, score: number) => {
  const analyze: Analyze = {
    PHQ: (score: number) => phqResult(score),
    ISI: (score: number) => isiResult(score),
    SSI: (score: number) => ssiResult(score),
  }
  return analyze[test]?.(score) ?? '구현되지 않은 마음검사 입니다'
}

export const testStatus = (color: string) => {
  const colors: Color = {
    '#399640': 'mild',
    '#FBB500': 'Moderate',
    '#C21A1A': 'Severe',
    gray: '검사결과 없음',
  }
  return colors[color]
}

const phqResult = (score: number) => {
  if (score <= 9) {
    return '#399640'
  } else if (score <= 19) {
    return '#FBB500'
  } else {
    return '#C21A1A'
  }
}

const isiResult = (score: number) => {
  if (score <= 14) {
    return '#399640'
  } else if (score <= 19) {
    return '#FBB500'
  } else {
    return '#C21A1A'
  }
}

const ssiResult = (score: number) => {
  if (score <= 7) {
    return '#399640'
  } else {
    return '#C21A1A'
  }
}
