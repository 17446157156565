import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({ storage: sessionStorage })

export const diaryPage = atom<number | undefined>({
  key: 'diaryPage',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
})
