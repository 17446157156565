import { Box, Button, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import { Pagination, NotifyModal } from 'component'
import { Diary } from 'model'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { UserService } from 'service'
import { DiaryCard } from './Diary'

export function InterestContent() {
  const { id } = useParams()
  const notifyModal = useDisclosure()
  const [diaryList, setDiaryList] = useState<Diary[]>([])
  const [total, setTotal] = useState<number>(-1)
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPage] = useState<number>(1)

  useQuery(
    ['getUserDiary', page],
    async () => {
      return await UserService.getDiaries({ id: Number(id), limit: 5, page })
    },
    {
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        setDiaryList(data.data)
        setTotal(data.meta.total)
        setPage(data.meta.currentPage)
        setTotalPage(data.meta.totalPages)
      },
    },
  )

  return (
    <Box>
      <NotifyModal state={notifyModal} user={Number(id)} />
      <Flex align="center" justify="space-between" mb={5}>
        <Heading as="h4" size="md" pb={4}>
          마음일기
        </Heading>
        <Button onClick={notifyModal.onOpen}>알림 보내기</Button>
      </Flex>
      <Flex gap={5} flexDirection="column">
        {diaryList.length > 0 &&
          diaryList.map((diary) => <DiaryCard key={diary.id} diary={diary} />)}
      </Flex>
      <Pagination
        current={page}
        total={total}
        totalPages={totalPages}
        perPage={5}
        setPage={(p) => {
          setPage(p)
        }}
      />
    </Box>
  )
}
