/* eslint-disable @typescript-eslint/indent */
import { Box, Flex, Text } from '@chakra-ui/react'
import { Test } from 'model'
import { FaCircle } from 'react-icons/fa'
import dayjs from 'dayjs'
import { testResult, testStatus } from 'helpers'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function TestItem({ name, test }: { name: string; test?: Test }) {
  const color = test ? testResult(name, test.score) : 'gray'

  return (
    <Box boxShadow="lg" p={6} borderRadius="2xl" bgColor="white" w="100%">
      <Flex flexDirection="column" gap={10}>
        <Flex direction="row" justifyContent="space-between">
          <Text>{name}</Text>
          <Text>
            {test
              ? dayjs(test.createdAt).utc().format('YYYY년 M월 D일')
              : '검사결과 없음'}
          </Text>
        </Flex>
        <Flex direction="row" justifyContent="space-between">
          <Flex direction="row" gap={4} alignItems="center">
            <FaCircle color={color} />
            <Text color={color}>{testStatus(color)}</Text>
          </Flex>
          <Text>{test ? test.score : '-'}</Text>
        </Flex>
      </Flex>
    </Box>
  )
}
