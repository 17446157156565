import { DiaryUser } from './User'
import { DiaryContent } from './Content'
import { DiaryPrompt } from './Prompt'

export const DiaryDetail = {
  User: DiaryUser,
  Content: DiaryContent,
  Prompt: DiaryPrompt
}
export * from './AutoAnswerContainer'
