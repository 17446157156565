import { DiaryService } from 'service'
import { wrAnswer } from 'store'
import { useSetRecoilState } from 'recoil'
import { useCallback, useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { getCookie } from 'helpers'

function useConfirmExit(confirmExit: () => boolean, id: number, when = true) {
  const setStoreAnswer = useSetRecoilState(wrAnswer)
  const { navigator } = useContext(NavigationContext)
  async function cancelAnswer() {
    await DiaryService.cancelAnswer(id)
  }

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit()
      if (result) {
        cancelAnswer()
        setStoreAnswer({ id: undefined, content: '' })
        push(...args)
      }
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}

export function usePrompt(message: string, id: number, when = true) {
  const setStoreAnswer = useSetRecoilState(wrAnswer)
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        setStoreAnswer({ id: undefined, content: '' })
        return message
      }

      window.onunload = function () {
        const token = getCookie('accessToken') ?? ''
        fetch(
          `https://server.doctorpresso.com/admin/diaries/${id}/answering/cancle`,
          {
            method: 'PATCH',
            keepalive: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
      }
    }

    return () => {
      window.onbeforeunload = null
      window.onunload = null
    }
  }, [message])

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message)
    return confirm
  }, [message])

  useConfirmExit(confirmExit, id, when)
}
