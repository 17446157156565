import { Box, Text, Flex, IconButton } from '@chakra-ui/react'
import { AutoAnswer } from 'model'
import {
  FaHeart,
  FaRegHeart,
  FaRegClipboard,
  FaRegThumbsDown,
  FaThumbsDown,
} from 'react-icons/fa'
import { useMutation } from 'react-query'
import { AutoAnswerService } from 'service'

export function AutoAnswerBox({
  answer,
  refetch,
  copy,
}: {
  answer: AutoAnswer
  refetch: () => void
  copy: (answer: string) => void
}) {
  const likeAnswer = useMutation(
    ['likeAnswer'],
    async (answerId: number) => {
      return await AutoAnswerService.likeAnswer(answerId)
    },
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  const dislikeAnswer = useMutation(
    ['dislikeAnswer'],
    async (answerId: number) => {
      return await AutoAnswerService.dislikeAnswer(answerId)
    },
    {
      onSuccess: () => {
        refetch()
      },
    },
  )

  return (
    <Box bg="white" borderRadius="md" p="6">
      <Text>{answer.contents}</Text>
      <Text fontSize="0.8rem" color="gray.400">
        {`공백제외 ${answer.contents.replace(/\s/gi, '').length}자 공백포함 ${
          answer.contents.length
        }자`}
      </Text>
      <Flex justify="space-between">
        <Box>
          <IconButton
            bg="transparent"
            borderRadius="full"
            icon={answer.like ? <FaHeart /> : <FaRegHeart />}
            aria-label="like btn"
            onClick={() => {
              likeAnswer.mutate(answer.id)
            }}
          />
          <IconButton
            bg="transparent"
            borderRadius="full"
            icon={<FaRegClipboard />}
            aria-label="copy btn"
            onClick={() => {
              copy(answer.contents)
            }}
          />
        </Box>
        <IconButton
          bg="transparent"
          borderRadius="full"
          icon={answer.dislike ? <FaThumbsDown /> : <FaRegThumbsDown />}
          aria-label="dislike btn"
          onClick={() => {
            dislikeAnswer.mutate(answer.id)
          }}
        />
      </Flex>
    </Box>
  )
}
