import { Box, Flex, Spacer, Text } from '@chakra-ui/layout'
import { IconButton, Select, Textarea } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { NoticeCategory } from 'model'
import { useEffect, useState } from 'react'
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa'
import { Input } from './notifyModal/NotifyModal'
import { PickerCalendarBtn } from './notifyModal/picker-calendar/Calendar'
import { TimePicker } from './notifyModal/TimePicker'

export function CreateNotice({ setInput }: { setInput: (v: Input) => void }) {
  const [contents, setContents] = useState<string>('')
  const [category, setCategory] = useState<NoticeCategory>('diary')
  const [reserve, setReserve] = useState(true)
  const [date, setDate] = useState<string>('')
  const [time, setTime] = useState<string>('PM')
  const [h, setH] = useState<string>('6')
  const [m, setM] = useState<string>('0')

  useEffect(() => {
    setInput({
      category,
      contents,
      date: new Date(`${date} ${time === 'PM' ? Number(h) + 12 : h}:${m}`),
    })
  }, [category, contents, date, h, m])

  return (
    <Box>
      <Select
        bg="white"
        w="50%"
        value={category}
        onChange={(e) => {
          if (
            e.target.value !== 'diary' &&
            e.target.value !== 'test' &&
            e.target.value !== 'notice' &&
            e.target.value !== 'etc'
          ) {
            return
          }
          setCategory(e.target.value)
        }}
      >
        <option value="diary">마음일기 알림</option>
        <option value="test">마음검사 알림</option>
        <option value="notice">공지</option>
        <option value="etc">기타</option>
      </Select>
      <Spacer h={2} />
      <Textarea
        bg="white"
        h="150px"
        resize="none"
        value={contents}
        onChange={(e) => {
          if (e.target.value.length > 300) {
            return
          }
          setContents(e.target.value)
        }}
      />
      <Text textAlign={'end'}>{contents.length} / 300</Text>

      <Spacer h={4} />
      <Flex align="center">
        <IconButton
          icon={reserve ? <FaCheckCircle /> : <FaRegCheckCircle />}
          p={0}
          borderRadius="full"
          aria-label="checkbox"
          bg="transparent"
          onClick={() => {
            setReserve(!reserve)
          }}
        />
        <Text
          cursor="pointer"
          onClick={() => {
            setReserve(!reserve)
          }}
        >
          예약발송
        </Text>
      </Flex>
      {reserve && (
        <Box ml={10}>
          <Flex align="center" gap={1}>
            <Text>날짜 : </Text>
            <Box
              as="p"
              h="24px"
              w="100px"
              borderBottomWidth="1px"
              borderColor="gray.500"
              textAlign="center"
            >
              {date !== '' ? dayjs(date).format('M월 D일') : ''}
            </Box>
            <PickerCalendarBtn
              getDate={(d) => {
                setDate(d)
              }}
            />
          </Flex>
          <Flex align="center" gap={1}>
            <Text>시간 :</Text>
            <Box
              as="p"
              h="24px"
              w="100px"
              borderBottomWidth="1px"
              borderColor="gray.500"
              textAlign="center"
            >
              {time} {h}시 {m}분
            </Box>
            <TimePicker
              _time={time}
              _h={h}
              _m={m}
              getTime={(v) => {
                setTime(v)
              }}
              getM={(v) => {
                setM(v)
              }}
              getH={(v) => {
                setH(v)
              }}
            />
          </Flex>
        </Box>
      )}
    </Box>
  )
}
