export type NoticeCategory = 'diary' | 'test' | 'notice' | 'etc'
export type NoticeTitleType = 'answer' | 'write' | 'testDate' | 'test'
export type NoticeDataType = {
  category: NoticeCategory
  label: string
  multiple: boolean
}

export type Notice = Record<NoticeTitleType, NoticeDataType>
export const NoticeData: Notice = {
  answer: {
    category: 'diary',
    label: '마음일기 답변 알림',
    multiple: false,
  },
  write: {
    category: 'diary',
    label: '마음일기 작성 독려',
    multiple: true,
  },
  testDate: {
    category: 'test',
    label: '마음검사일 알림',
    multiple: false,
  },
  test: {
    category: 'test',
    label: '마음검사 받기 독려',
    multiple: true,
  },
}

export type NoticeSetting = {
  id: number

  category: NoticeCategory
  title: NoticeTitleType

  contents: string
  secondContents?: string
  thirdContents?: string
  firstPeriod?: number
  firstUnit?: 'w' | 'M'
  secondPeriod?: number
  secondUnit?: 'w' | 'M'
  thirdPeriod?: number
  thirdUnit?: 'w' | 'M'

  hour: number
  minute: number
  isActive: boolean
}
