import dayjs from 'dayjs'
import Calendar from 'react-calendar'
import './picker-calendar.css'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  IconButton,
} from '@chakra-ui/react'
import { FaRegCalendarAlt } from 'react-icons/fa'

function PickerCanlendar({
  getReserveTime,
}: {
  getReserveTime: (date: string) => void
}) {
  return (
    <Calendar
      className="react-calendar picker-calendar"
      tileClassName="tile"
      calendarType="US"
      locale="ko"
      next2Label={null}
      prev2Label={null}
      showNeighboringMonth={false}
      formatDay={(locale, date) => dayjs(date).format('D')}
      onClickDay={(a) => {
        getReserveTime(dayjs(a).format('YYYY-MM-DD'))
      }}
      onActiveStartDateChange={({ activeStartDate }) => {
        getReserveTime(dayjs(activeStartDate).format('YYYY-MM-DD'))
      }}
    />
  )
}

export function PickerCalendarBtn({
  getDate,
}: {
  getDate: (d: string) => void
}) {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="open calendar"
          icon={<FaRegCalendarAlt />}
          bg="transparent"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <PickerCanlendar
            getReserveTime={(d) => {
              getDate(d)
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
