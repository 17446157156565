import { UseToastOptions } from '@chakra-ui/react'

type ToastThemeType = Record<string, Pick<UseToastOptions, 'status' | 'duration' | 'position' | 'containerStyle'>>

export const ToastTheme: ToastThemeType = {
  success: {
    status: 'success',
    duration: 1000,
    position: 'top',
    containerStyle: { background: 'green' },
  },
  error: {
    status: 'error',
    duration: 1000,
    position: 'top',
    containerStyle: { background: 'red' },
  },
}
