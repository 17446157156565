import {
  Grid,
  IconButton,
  NumberInput,
  NumberInputField,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
  Flex,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FaRegClock } from 'react-icons/fa'

export function TimePicker({
  _time,
  _h,
  _m,
  getTime,
  getH,
  getM,
}: {
  _time: string
  _h: string
  _m: string
  getTime: (t: string) => void
  getH: (t: string) => void
  getM: (t: string) => void
}) {
  const [time, setTime] = useState<string>(_time)
  const [h, setH] = useState<string>(_h)
  const [m, setM] = useState<string>(_m)

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="open time picker"
          icon={<FaRegClock />}
          bg="transparent"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Grid gap={2} templateColumns="1fr 1fr 1fr">
            <Select
              value={time}
              onChange={(v) => {
                setTime(v.target.value)
                getTime(v.target.value)
              }}
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </Select>
            <Flex align="center" gap={1}>
              <NumberInput
                allowMouseWheel
                defaultValue={6}
                min={1}
                max={12}
                step={1}
                value={h}
                onChange={(v) => {
                  setH(v)
                  getH(v)
                }}
              >
                <NumberInputField />
              </NumberInput>
              <Text>시</Text>
            </Flex>
            <Flex align="center" gap={1}>
              <NumberInput
                allowMouseWheel
                defaultValue={0}
                min={0}
                max={59}
                step={1}
                value={m}
                onChange={(v) => {
                  setM(v)
                  getM(v)
                }}
              >
                <NumberInputField />
              </NumberInput>
              <Text>분</Text>
            </Flex>
          </Grid>
          <Text as="small" display="inline-block" _before={{ content: '"• "' }}>
            시간은 1~12, 분은 0~59 사이의 숫자로 입력해주세요.
          </Text>
          <Text as="small" display="inline-block" _before={{ content: '"• "' }}>
            숫자는 스크롤하거나 직접 입력하여 변경할 수 있습니다.
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
