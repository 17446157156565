/* eslint-disable @typescript-eslint/indent */
import { Text, Textarea, Button, Box, Flex, useToast } from '@chakra-ui/react'
import {
  DetailCondition,
  DetailConditionType,
  Layout,
  Row,
  Section,
  SubTitle,
  ToastTheme,
} from 'component'
import { TimePicker } from 'component/notice/notifyModal/TimePicker'
import { NoticeTitleType, NoticeSetting, NoticeData } from 'model'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NoticeService } from 'service'

export default function NoticeManageScreen() {
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()
  const notice = location.state as NoticeSetting
  const title = useParams().title as unknown as NoticeTitleType

  const [time, setTime] = useState<string>(notice.hour > 12 ? 'PM' : 'AM')
  const [h, setH] = useState<string>(
    notice.hour > 12 ? String(notice.hour - 12) : String(notice.hour),
  )
  const [m, setM] = useState<string>(String(notice.minute))
  const [contents, setContents] = useState<string>(notice.contents)
  const [detailData, setDetailData] = useState<DetailConditionType[]>([])
  const getData = (data: DetailConditionType[]) => {
    setDetailData(data)
  }

  const updateNoticeSetting = useMutation(
    ['updateNoticeSetting', notice.id],
    async () => {
      let changeData: Pick<
        NoticeSetting,
        | 'contents'
        | 'firstPeriod'
        | 'firstUnit'
        | 'secondContents'
        | 'secondPeriod'
        | 'secondUnit'
        | 'thirdContents'
        | 'thirdPeriod'
        | 'thirdUnit'
      > = {
        contents,
        firstUnit: undefined,
        firstPeriod: undefined,
        secondContents: undefined,
        secondUnit: undefined,
        secondPeriod: undefined,
        thirdContents: undefined,
        thirdUnit: undefined,
        thirdPeriod: undefined,
      }
      if (detailData.length > 0) {
        changeData = {
          contents: detailData[0].contents,
          firstUnit: detailData[0].unit,
          firstPeriod: detailData[0].period,
          secondContents: detailData[1].contents,
          secondUnit: detailData[1].unit,
          secondPeriod: detailData[1].period,
          thirdContents: detailData[2].contents,
          thirdUnit: detailData[2].unit,
          thirdPeriod: detailData[2].period,
        }
      }
      return await NoticeService.updateNoticeSetting(notice.id, {
        ...changeData,
        hour: time === 'PM' ? Number(h) + 12 : Number(h),
        minute: Number(m),
      })
    },
    {
      onSuccess: () => {
        toast({ description: '저장 되었습니다.', ...ToastTheme.success })
        navigate('/notice')
      },
    },
  )

  return (
    <Layout title="통합 알림 설정">
      <SubTitle title="기본 알림 관리" />
      <Section sx={{ p: 6 }}>
        <Row title="카테고리">
          <Text>
            {notice?.category === 'diary' ? '마음일기 알림' : '마음검사 알림'}
          </Text>
        </Row>
        <Row title="제목">
          <Text>{NoticeData[notice.title].label}</Text>
        </Row>
        {(title === 'answer' || title === 'testDate') && (
          <Row title="내용">
            <Textarea
              value={contents}
              onChange={(e) => {
                e.preventDefault()
                setContents(e.target.value)
              }}
              w="500px"
            />
          </Row>
        )}
        <Row title="발송 시간">
          <Flex align="center" gap={1}>
            <Box
              as="p"
              h="24px"
              w="100px"
              borderBottomWidth="1px"
              borderColor="gray.500"
              textAlign="center"
            >
              {time} {h}시 {m}분
            </Box>
            <TimePicker
              _time={time}
              _h={h}
              _m={m}
              getTime={(v) => {
                setTime(v)
              }}
              getM={(v) => {
                setM(v)
              }}
              getH={(v) => {
                setH(v)
              }}
            />
          </Flex>
        </Row>
        {notice.firstPeriod && (
          <DetailCondition getData={getData} data={notice} />
        )}
        <Button
          onClick={() => {
            updateNoticeSetting.mutate()
          }}
        >
          저장
        </Button>
      </Section>
    </Layout>
  )
}
