import { Box, Heading, Spacer } from '@chakra-ui/react'
import { ReactNode } from 'react'

export function Layout({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  return (
    <Box p={14}>
      <Heading as="h1">{title}</Heading>
      <Spacer h="6"/>
      {children}
    </Box>
  )
}
