import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ChakraProvider, Flex, extendTheme } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import Router from 'Router'
import { RecoilRoot } from 'recoil'

export const theme = extendTheme({
  colors: {
    darkblue: '#344A7E',
    blue: '#55AFEF',
    yellow: '#FBB500',
    green: '#399640',
    red: '#C21A1A',
  },
  fonts: {
    body: 'Noto Sans KR, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    heading:
      'Noto Sans KR, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50',
      },
    },
  },
})

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RecoilRoot>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Flex as="main" h="100vh">
            <Router />
          </Flex>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  </RecoilRoot>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
