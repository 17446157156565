import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ToastTheme } from 'component'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import authService from 'service/auth.service'

export default function LoginScreen() {
  const toast = useToast()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)

  const handleClick = () => {
    setShow(!show)
  }

  const login = async () => {
    try {
      await authService.login(name, password)
      toast({
        description: '로그인되었습니다',
        ...ToastTheme.success,
      })
      navigate('/')
    } catch (err) {
      toast({
        description: '아이디와 비밀번호를 확인하세요',
        ...ToastTheme.error,
      })
    }
  }
  return (
    <Flex flexDir="column" h="100vh" w="100%" justify="center" align="center">
      <Text mb={3} fontSize={'x-large'}>
        DoctorPresso 관리자
      </Text>
      <Input
        w={250}
        mb={3}
        placeholder="아이디"
        value={name}
        onChange={(e) => {
          setName(e.target.value)
        }}
        focusBorderColor="darkblue"
      />
      <InputGroup w={250} mb={3}>
        <Input
          type={show ? 'text' : 'password'}
          placeholder="비밀번호"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              login()
            }
          }}
          focusBorderColor="darkblue"
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? '숨기기' : '보이기'}
          </Button>
        </InputRightElement>
      </InputGroup>
      <Button
        w={250}
        bg={'gray'}
        _hover={{ backgroundColor: 'darkblue' }}
        color={'whiteAlpha.700'}
        onClick={() => {
          login()
        }}
      >
        로그인
      </Button>
    </Flex>
  )
}
