import {
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Textarea,
  VStack,
  Button,
} from '@chakra-ui/react'
import { Section } from 'component'
import { AutoAnswerParam } from 'model'
import { useState } from 'react'

export function Prompt({
  setParam,
}: {
  setParam: (param: AutoAnswerParam) => void
}) {
  const [prompt, setPrompt] = useState<string>('')
  const [temperature, setTemperature] = useState<number>(1)
  const [maxTokens, setMaxTokens] = useState<number>(300)

  const createAnswer = () => {
    const params: AutoAnswerParam = { prompt, temperature, maxTokens }
    setParam(params)
  }

  return (
    <Section title="프롬프트 입력" sx={{ p: '6' }}>
      <VStack gap={4} display="inline-block" w="100%">
        <Textarea
          h="500px"
          value={prompt}
          onChange={(e) => {
            setPrompt(e.target.value)
          }}
          resize="none"
        />
        <Box>
          <Text as="b">생성 자유도 (1~10)</Text>
          <NumberInput
            value={temperature}
            onChange={(_, v) => {
              setTemperature(v)
            }}
            min={1}
            max={10}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box>
          <Text as="b">생성 최대 길이 (10~4000)</Text>
          <NumberInput
            value={maxTokens}
            onChange={(_, v) => {
              setMaxTokens(v)
            }}
            min={10}
            max={4000}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box>
          <Text as="b">반복 실행 횟수 </Text>
          <NumberInput value={1}>
            <NumberInputField />
          </NumberInput>
        </Box>
        <Button
          onClick={() => {
            createAnswer()
          }}
        >
          텍스트 생성
        </Button>
      </VStack>
    </Section>
  )
}
