import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react'
import React from 'react'
import { useQuery } from 'react-query'
import { UserService } from 'service'
import { TestItem } from './TestItem'

export function TestCard({ id }: { id: number }) {
  const { data, isLoading } = useQuery(['getTests', id], async () => {
    return await UserService.getTests(Number(id))
  })

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <AccordionButton gap={5}>
          <AccordionIcon />
          <Box as="span">마음상태</Box>
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Flex gap={10}>
            {!isLoading &&
              data?.map((category) => (
                <TestItem
                  key={category.id}
                  name={category.name}
                  test={category.test[0]}
                />
              ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
