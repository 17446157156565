import { Box, Text, Spacer } from '@chakra-ui/react'

export function Status({ name, count }: { name: string, count: number }) {
  return (
    <Box minW="150px" textAlign="center">
      <Text as="h4" size="md" fontWeight="bold">
        {name}
      </Text>
      <Spacer h={4} />
      <Box bg="gray.300" textAlign="center" p="8" borderRadius="full" display="inline-block">
        {count} 개
      </Box>
    </Box>
  )
}
