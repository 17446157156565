import { Flex, IconButton, Text, useToast } from '@chakra-ui/react'
import { ToastTheme } from 'component'
import { User } from 'model'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { useMutation } from 'react-query'
import { UserService } from 'service'

export function InterestUser({
  user,
  refetch,
}: {
  user: User
  refetch: () => void
}) {
  const toast = useToast()
  const concernUser = useMutation(
    ['concernUser'],
    async (userId: number) => {
      return await UserService.concernUser(userId)
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '관심인원으로 등록되었습니다.',
            ...ToastTheme.success,
          })
        } else {
          toast({
            description: '관심인원에서 해제되었습니다.',
            ...ToastTheme.success,
          })
        }
        refetch()
      },
    },
  )
  return (
    <Flex align="center" gap={2} justify="space-between" px={2}>
      <Flex align="center" gap={2}>
        <IconButton
          bg="transparent"
          borderRadius="full"
          aria-label="user isConcerned btn"
          icon={user.isConcerned ? <FaStar /> : <FaRegStar />}
          onClick={() => {
            concernUser.mutate(user.id)
          }}
        />
        <Text>닉네임</Text>
        <Text as="b" bg="gray.200" px={4} py={1} borderRadius="full">
          {user.nick}
        </Text>
      </Flex>
    </Flex>
  )
}
