import { Text, VStack } from '@chakra-ui/react'
import { Section, PromptResultBox } from 'component'
import { ResponseChoice } from 'model'

export function PromptResult({
  loading,
  resultList,
}: {
  loading: boolean
  resultList: ResponseChoice[]
}) {
  return (
    <Section
      title="프롬프트 결과"
      sx={{ p: '6', h: '850px', overflow: 'auto' }}
    >
      {loading ? (
        <Text w="100%" textAlign="center">
          생성 중...
        </Text>
      ) : (
        <VStack gap={2} align="stretch">
          {resultList.map((result) => (
            <PromptResultBox key={result.index} text={result.message.content} />
          ))}
        </VStack>
      )}
    </Section>
  )
}
