import {
  Button,
  Select,
  Spacer,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import {
  CreateNotice,
  Input,
  Layout,
  Section,
  SubTitle,
  ToastTheme,
} from 'component'
import { NoticeCategory, NoticeData, NoticeSetting } from 'model'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { NoticeService } from 'service'

export default function NoticeScreen() {
  const toast = useToast()
  const navigate = useNavigate()
  const [user, setUser] = useState<'total' | 'interest'>('total')
  const [noticeList, setNoticeList] = useState<NoticeSetting[]>([])
  const defaultValue = {
    category: 'diary' as NoticeCategory,
    contents: '',
    date: new Date(),
  }
  const [input, setInput] = useState<Input>(defaultValue)

  const getNoticeSettings = useQuery(
    ['getNoticeSettings'],
    async () => await NoticeService.getNoticeSettings(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setNoticeList(data)
      },
    },
  )

  const activateNoticeSetting = useMutation(
    ['activateNoticeSetting'],
    async (id: number) => {
      return await NoticeService.activateNoticeSetting(id)
    },
    {
      onSuccess: () => {
        getNoticeSettings.refetch()
      },
    },
  )

  const sendNotifications = useMutation(
    ['sendNotification', user],
    async ({
      category,
      contents,
      date,
    }: {
      category: NoticeCategory
      contents: string
      date: Date
    }) => {
      return await NoticeService.sendNotices({
        concern: user === 'interest',
        category,
        contents,
        date,
      })
    },
    {
      onSuccess: () => {
        toast({ description: '알림을 전송하였습니다', ...ToastTheme.success })
      },
      onError: () => {
        toast({
          description: '알림 전송을 실패하였습니다',
          ...ToastTheme.error,
        })
      },
    },
  )

  return (
    <Layout title="통합 알림 설정">
      <Section sx={{ p: 10 }}>
        <SubTitle title="기본 알림 설정" />
        <Table>
          <Thead>
            <Tr>
              <Th>카테고리</Th>
              <Th>제목</Th>
              <Th>활성화</Th>
              <Th>관리</Th>
            </Tr>
          </Thead>
          <Tbody>
            {noticeList.map((item) => {
              return (
                <Tr key={item.id}>
                  <Td>
                    {item.category === 'diary'
                      ? '마음일기 알림'
                      : '마음검사 알림'}
                  </Td>
                  <Td>{NoticeData[item.title].label}</Td>
                  <Td>
                    <Switch
                      colorScheme="teal"
                      isChecked={item.isActive}
                      onChange={() => {
                        activateNoticeSetting.mutate(item.id)
                      }}
                    />
                  </Td>
                  <Td py={1}>
                    <Button
                      onClick={() => {
                        navigate(`${item.title}`, { state: { ...item } })
                      }}
                    >
                      수정
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Section>
      <Spacer h={10} />
      <Section sx={{ p: 10 }}>
        <SubTitle title="추가 알림 보내기" />
        <Select
          bg="white"
          w="50%"
          placeholder="대상자 선택"
          value={user}
          onChange={(e) => {
            if (e.target.value !== 'total' && e.target.value !== 'interest') {
              return
            }
            setUser(e.target.value)
          }}
        >
          <option value="total">전체</option>
          <option value="interest">관심인원 전체</option>
        </Select>
        <Spacer h={2} />
        <CreateNotice setInput={setInput} />
        <Button
          my={4}
          onClick={() => {
            sendNotifications.mutate(input)
          }}
        >
          전송
        </Button>
      </Section>
    </Layout>
  )
}
