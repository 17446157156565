import { Box, Button, Spacer, Text, useToast, VStack } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Layout, SubTitle, ToastTheme, InputBox } from 'component'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AuthService } from 'service'

type Data = {
  message: string[]
  statusCode: number
}

export default function SettingScreen() {
  const toast = useToast()
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  useQuery(
    ['getAdmin'],
    async () => {
      return await AuthService.getAdmin()
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setName(data.name)
      },
    },
  )

  const changePassword = useMutation(
    ['changePassword'],
    async () =>
      await AuthService.changPassword({
        password,
        newPassword,
      }),
    {
      onSuccess: () => {
        toast({
          description: '비밀번호가 변경되었습니다.',
          ...ToastTheme.success,
        })
      },
      onError: (error: AxiosError) => {
        const message = (error.response?.data as Data).message
        toast({
          description: message,
          ...ToastTheme.error,
        })
      },
    },
  )

  return (
    <Layout title="설정">
      <SubTitle title="관리자 정보" />
      <Box w="350px">
        <Text as="b" mb={2} display="block" fontSize="1.2rem">
          관리자 아이디
        </Text>
        <Box bg="white" borderRadius="md" p="3">
          <Text>{name}</Text>
        </Box>
      </Box>
      <Spacer h={20} />
      <SubTitle title="관리자 비밀번호" />
      <VStack gap={3} alignItems="flex-start">
        <InputBox
          label="현재 비밀번호"
          value={password}
          setValue={setPassword}
          type="password"
        />
        <InputBox
          label="새 비밀번호"
          value={newPassword}
          setValue={setNewPassword}
          type="password"
        />
        <InputBox
          label="새 비밀번호 확인"
          value={confirmNewPassword}
          setValue={setConfirmNewPassword}
          type="password"
          isValid={newPassword === confirmNewPassword}
          error="입력한 비밀번호와 동일하게 입력해주세요"
        />
        <Button
          style={{ borderRadius: 'lg' }}
          w="350px"
          onClick={() => {
            changePassword.mutateAsync()
          }}
          isDisabled={
            !newPassword || !password || newPassword !== confirmNewPassword
          }
        >
          변경
        </Button>
      </VStack>
    </Layout>
  )
}
