import { Box, BoxProps, Flex, Heading } from '@chakra-ui/react'
import { ReactNode } from 'react'

export function Section({
  children,
  rightComponent,
  title,
  sx,
}: {
  children: ReactNode
  rightComponent?: ReactNode
  title?: string
  sx?: BoxProps
}) {
  return (
    <Box as="section" boxShadow="xl" borderRadius="xl" sx={sx} bg="white">
      <Flex justify="space-between">
        {title && (
          <Heading as="h4" size="md" pb={4}>
            {title}
          </Heading>
        )}
        {rightComponent ?? null}
      </Flex>
      {children}
    </Box>
  )
}
