import {
  Box,
  Divider,
  Grid,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Pagination } from 'component/common'
import { Prompt } from 'model'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { OptionBtn } from './OptionBtns'

type DisclosureObject = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  getDisclosureProps: () => void
  getButtonProps: () => void
}

export function PromptSettingModal({
  state,
  page,
  total,
  totalPages,
  setting,
  refetch,
  setPage,
  setId,
  setReId,
  setDefaultSet,
}: {
  state: DisclosureObject
  page: number
  total: number
  totalPages: number
  setting: Prompt[]
  refetch: (arg: boolean) => void
  setPage: Dispatch<SetStateAction<number>>
  setId: Dispatch<SetStateAction<number>>
  setReId: Dispatch<SetStateAction<boolean>>
  setDefaultSet: Dispatch<SetStateAction<boolean>>
}) {
  const [active, setActive] = useState<Prompt>()
  const [edit, setEdit] = useState<boolean>(false)
  const [prompt, setPrompt] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [temperature, setTemperature] = useState<number>(0)
  const [maxTokens, setMaxTokens] = useState<number>(0)

  useEffect(() => {}, [setting])
  useEffect(() => {
    if (active) {
      setName(active.name)
      setPrompt(active.prompt)
      setTemperature(active.temperature)
      setMaxTokens(active.maxTokens)
    }
  }, [active])

  const EditElem = ({
    value,
    setValue,
    multiline,
  }: {
    value: string | number
    setValue: (v: string) => void
    multiline?: boolean
  }) => {
    return (
      <>
        {edit ? (
          !multiline ? (
            <Input
              key={value}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            />
          ) : (
            <Textarea
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            />
          )
        ) : (
          <Text>{value}</Text>
        )}
      </>
    )
  }

  return (
    <Modal isOpen={state.isOpen} onClose={state.onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>프롬프트 설정 관리</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table alignSelf="flex-start" flex={1}>
            <Thead>
              <Tr>
                <Th></Th>
                <Th>이름</Th>
                <Th>내용</Th>
                <Th>생성 자유도(1~10)</Th>
                <Th>생성 최대 길이(10~4000)</Th>
                <Th>반복 실행 횟수</Th>
              </Tr>
            </Thead>
            <Tbody>
              {setting.map((p: Prompt) => {
                return (
                  <Tr
                    key={p.id}
                    onClick={() => {
                      setActive(p)
                    }}
                    cursor="pointer"
                    bg={active && p.id === active.id ? '#EDF2F7' : 'white'}
                    _hover={{ bg: '#F7FAFC' }}
                  >
                    <Td>
                      {p.isBasic && (
                        <Tag variant="solid" bg="green">
                          기본
                        </Tag>
                      )}
                    </Td>
                    <Td>{p.name}</Td>
                    <Td>
                      {p.prompt.length > 15
                        ? `${p.prompt.slice(0, 15)}...`
                        : p.prompt}
                    </Td>
                    <Td>{p.temperature}</Td>
                    <Td>{p.maxTokens}</Td>
                    <Td>1</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          {active && (
            <>
              <Divider />
              <Box pt={10}>
                <Heading as="h4" size="md">
                  프롬프트 설정
                </Heading>
                <OptionBtn
                  state={state}
                  getEdit={(e: boolean) => {
                    setEdit(e)
                  }}
                  setContent={() => {
                    const content: Prompt = {
                      id: active.id,
                      name,
                      prompt,
                      temperature,
                      maxTokens,
                      isBasic: active.isBasic,
                    }
                    return content
                  }}
                  refetch={(arg: boolean) => {
                    refetch(arg)
                  }}
                  setId={setId}
                  setReId={setReId}
                  setDefaultSet={setDefaultSet}
                />
                <Grid templateColumns="0.5fr 1.5fr" gap={2}>
                  <Text as="b">이름</Text>
                  <EditElem
                    value={name}
                    setValue={(v) => {
                      setName(v)
                    }}
                  />
                  <Text as="b">내용</Text>
                  <EditElem
                    multiline
                    value={prompt}
                    setValue={(v) => {
                      setPrompt(v)
                    }}
                  />
                  <Text as="b">생성 자유도</Text>
                  <EditElem
                    value={temperature}
                    setValue={(v) => {
                      setTemperature(Number(v))
                    }}
                  />
                  <Text as="b">생성 최대 길이</Text>
                  <EditElem
                    value={maxTokens}
                    setValue={(v) => {
                      setMaxTokens(Number(v))
                    }}
                  />
                  <Text as="b">반복 실행 횟수</Text>
                  <Text>1</Text>
                </Grid>
              </Box>
            </>
          )}
          <Pagination
            current={page}
            total={total}
            perPage={5}
            totalPages={totalPages}
            setPage={(p) => {
              setPage(p)
              refetch(true)
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
