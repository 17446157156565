import { AutoAnswer } from 'model'
import { api } from '.'

class AutoAnswerService {
  async createAnswers({
    prompt,
    context,
    temperature,
    maxTokens,
    id,
  }: {
    prompt: string
    context: string
    temperature: number
    maxTokens: number
    id: number
  }) {
    return await api.post(`/answer/${id}`, {
      prompt,
      context,
      temperature,
      max_tokens: maxTokens,
    })
  }

  async getAnswers(id: number) {
    return await api.get<AutoAnswer[]>(`/answer/${id}`)
  }

  async getlikeAnswers(id: number) {
    return await api.get<AutoAnswer[]>(`/answer/${id}/likes`)
  }

  async likeAnswer(id: number) {
    return await api.post<boolean>(`/answer/${id}/likes`, {})
  }

  async dislikeAnswer(id: number) {
    return await api.post<boolean>(`/answer/${id}/dislikes`, {})
  }
}
export default new AutoAnswerService()
