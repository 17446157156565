import { Button, Input, useToast } from '@chakra-ui/react'
import { ToastTheme } from 'component/common'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { PromptService } from 'service'

type DisclosureObject = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  getDisclosureProps: () => void
  getButtonProps: () => void
}

export function NameInput({
  nameModal,
  setContent,
  refetch,
}: {
  nameModal: DisclosureObject
  setContent: () => any
  refetch: (arg: boolean) => void
}) {
  const toast = useToast()
  const [name, setName] = useState<string>('')
  const createSetting = useMutation(
    ['create new prompt setting'],
    async () => {
      const content = setContent()
      return await PromptService.create({
        name,
        ...content,
      })
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '프롬프트 설정이 추가되었습니다.',
            ...ToastTheme.success,
          })
          nameModal.onClose()
          refetch(true)
        }
      },
    },
  )

  return (
    <>
      <Input
        value={name}
        onChange={(e) => {
          setName(e.target.value)
        }}
      />
      <Button
        m={2}
        size="sm"
        onClick={() => {
          createSetting.mutate()
        }}
      >
        확인
      </Button>
      <Button size="sm" onClick={nameModal.onClose}>
        취소
      </Button>
    </>
  )
}
