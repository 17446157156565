/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { api } from '.'
import { SortOption, FilterOption, Diary, Meta } from 'model'

type Result = {
  data: Diary[]
  meta: Meta
}

class DiaryService {
  async getCalendarDiary(year: string, month: string) {
    return await api.get(`/diaries/calender?year=${year}&month=${month}`)
  }

  async get({
    limit,
    page = 1,
    search = '',
    sort = 'latest',
    filter = 'all',
  }: {
    limit: number
    page?: number
    search: string
    sort?: SortOption
    filter?: FilterOption
  }) {
    return await api.get<Result>(
      `/diaries?limit=${limit}&page=${page}&search=${search}&sort=${sort}&filter=${filter}`,
    )
  }

  async getCount() {
    return await api.get<number>('/diaries/count')
  }

  async getCountWaiting() {
    return await api.get<number>('/diaries/count/waitings')
  }

  async getDetail(id: number) {
    return await api.get<Diary>(`/diaries/${id}`)
  }

  async getWaiting() {
    return await api.get<Diary[]>('/diaries/waiting')
  }

  async createAnswer(id: number, answer: string) {
    return await api.patch<boolean>(`/diaries/${id}/answer`, { answer })
  }

  /**
   * 답변 중을 답변 완료로 변경 (isAnswering : False)
   * @param id
   * @returns
   */
  async cancelAnswer(id: number) {
    return await api.patch<boolean>(`/diaries/${id}/answering/cancle`)
  }

  /**
   * 답변 중으로 상태 변경 (isAnswering : True)
   * @param id
   * @returns
   */
  async isAnswering(id: number) {
    return await api.patch<boolean>(`/diaries/${id}/answering`)
  }
}
export default new DiaryService()
