import { Flex, IconButton, Text, useToast } from '@chakra-ui/react'
import { ToastTheme } from 'component'
import dayjs from 'dayjs'
import { Diary } from 'model'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { useMutation } from 'react-query'
import { UserService } from 'service'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function DiaryUser({
  diary,
  refetch,
}: {
  diary: Diary
  refetch: (v: boolean) => void
}) {
  const toast = useToast()
  const concernUser = useMutation(
    ['concernUser'],
    async (userId: number) => {
      return await UserService.concernUser(userId)
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '관심인원으로 등록되었습니다.',
            ...ToastTheme.success,
          })
        } else {
          toast({
            description: '관심인원에서 해제되었습니다.',
            ...ToastTheme.success,
          })
        }
        refetch(true)
      },
    },
  )
  return (
    <Flex align="center" gap={2} justify="space-between" px={2}>
      <Flex align="center" gap={2}>
        <IconButton
          bg="transparent"
          borderRadius="full"
          aria-label="user isConcerned btn"
          icon={diary.user.isConcerned ? <FaStar /> : <FaRegStar />}
          onClick={() => {
            concernUser.mutate(diary.userId)
          }}
        />
        <Text>닉네임</Text>
        <Text as="b" bg="gray.200" px={4} py={1} borderRadius="full">
          {diary.user.nick}
        </Text>
      </Flex>
      <Text>
        {dayjs(diary.createdAt).utc().format('YYYY년 M월 D일(ddd) A hh:mm')}
      </Text>
    </Flex>
  )
}
