import { Location, Meta } from 'model'
import { api } from '.'

type Result = {
  data: Location[]
  meta: Meta
}

class LocationService {
  async get({ limit, page = 1 }: { limit: number; page?: number }) {
    return await api.get<Result>(`/location?limit=${limit}&page=${page}`)
  }

  async create(input: Pick<Location, 'name'>) {
    return await api.post('/location', input)
  }
}
export default new LocationService()
