import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

import { Box, Flex, Divider, VStack, useToast } from '@chakra-ui/react'
import {
  Layout,
  ToastTheme,
  DiaryHeader,
  DiaryDetail,
  AutoAnswerContainer,
  TestCard,
} from 'component'
import { AutoAnswerService, DiaryService } from 'service'
import { AutoAnswerParam, Diary } from 'model'
import { usePrompt } from 'hook/usePrompt'
import { useRecoilState } from 'recoil'
import { wrAnswer } from 'store'

dayjs.locale('ko')

export default function DiaryDetailScreen() {
  const location = useLocation()
  const { id } = useParams()
  const toast = useToast()
  const [storeWrAnswer, setStoreWrAnswer] = useRecoilState(wrAnswer)
  const [answer, setAnswer] = useState<string>('')
  const [writingAnswer, setWritingAnswer] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [diaryContents, setDiaryContents] = useState<string>('')
  const [diary, setDiary] = useState<Diary>()
  const [reDiary, setReDiary] = useState<boolean>(true)

  useEffect(() => {
    async function cancelAnswer() {
      await DiaryService.cancelAnswer(Number(id))
    }
    function confirmPopup() {
      if (
        !window.confirm(
          '페이지를 닫으면 작성중인 내용이 모두 삭제됩니다. 페이지를 벗어나시겠습니까?',
        )
      ) {
        history.pushState(null, '', location.pathname)
        history.go(0)
      } else {
        cancelAnswer()
        setStoreWrAnswer({ id: -1, content: '' })
      }
    }
    window.addEventListener('popstate', confirmPopup)
    window.onblur = () => {
      window.removeEventListener('popstate', confirmPopup)
    }
  }, [])

  usePrompt(
    '페이지를 닫으면 작성중인 내용이 모두 삭제됩니다. 페이지를 벗어나시겠습니까?',
    Number(id),
  )

  useEffect(() => {
    async function isAnswering() {
      await DiaryService.isAnswering(Number(id))
    }
    isAnswering()
    if (storeWrAnswer.id === Number(id)) {
      setWritingAnswer(storeWrAnswer.content)
    }
  }, [id])

  useQuery(
    [`getDiaryDetail ${id ?? -1}`, id],
    async () => {
      return await DiaryService.getDetail(Number(id))
    },
    {
      enabled: reDiary,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDiaryContents(
          `${data.emotion} ${data.event} ${data.think} ${data.free}`,
        )
        setDiary(data)
        if (data.answer !== null) {
          setAnswer(data.answer.contents)
        }
        setReDiary(false)
      },
    },
  )
  const createAutoAnswers = useMutation(
    ['createAutoAnswers'],
    async (param: AutoAnswerParam) => {
      setIsLoading(true)
      return await AutoAnswerService.createAnswers({
        id: Number(id),
        context: diaryContents,
        ...param,
      })
    },
    {
      onSuccess: () => {
        setIsLoading(false)
        toast({ description: '답변이 생성되었습니다.', ...ToastTheme.success })
      },
      onError: () => {
        setIsLoading(false)
        toast({
          description: '답변 생성에 실패하였습니다',
          ...ToastTheme.error,
        })
      },
    },
  )

  return (
    <Layout title="마음일기 관리">
      <DiaryHeader />
      {diary && (
        <Box>
          <DiaryDetail.User diary={diary} refetch={setReDiary} />
          <TestCard id={diary.userId} />
          <Divider borderColor="gray.300" my={6} />
          <Flex flexDir="row" gap={6}>
            <VStack gap={6} w="100%" display="inline-block">
              <DiaryDetail.Content diary={diary} />
              <DiaryDetail.Prompt
                setParam={(param) => {
                  createAutoAnswers.mutate(param)
                }}
              />
            </VStack>
            <AutoAnswerContainer
              loading={isLoading}
              initAnswer={answer}
              writingAnswer={writingAnswer}
            />
          </Flex>
        </Box>
      )}
    </Layout>
  )
}
