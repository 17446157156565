/* eslint-disable @typescript-eslint/indent */
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Layout, InterestHeader, NotifyModal } from 'component'
import { User } from 'model'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { UserService } from 'service'
import dayjs from 'dayjs'
import { elapsedTime, testResult } from 'helpers'
import { FaCircle, FaStar } from 'react-icons/fa'
import { Pagination } from 'component/common/Pagination'
import { useNavigate } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default function InterestScreen() {
  const navigate = useNavigate()
  const notifyModal = useDisclosure()
  const [selectedId, setSelectedId] = useState<number>(-1)

  const [interestList, setInterestList] = useState<User[]>([])
  const [total, setTotal] = useState<number>(-1)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPage] = useState<number>(1)

  useQuery(
    ['getAllInterest', page, search],
    async () => {
      return await UserService.getInterests({ limit: 10, page, search })
    },
    {
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        setInterestList(data.data)
        setTotal(data.meta.total)
        setPage(data.meta.currentPage)
        setTotalPage(data.meta.totalPages)
      },
    },
  )
  const enterSearch = (searchWord: string) => {
    setSearch(searchWord)
  }

  return (
    <Layout title="관심인원 관리">
      <InterestHeader setSearch={enterSearch} />
      <Box>
        {interestList.length === 0 && (
          <Box>
            <Text>관심인원이 없습니다.</Text>
          </Box>
        )}
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>닉네임</Th>
                <Th>마음일기 작성일</Th>
                <Th>마음 상태</Th>
                <Th>권유 알림</Th>
              </Tr>
            </Thead>
            {interestList.length > 0 && (
              <Tbody>
                {interestList.map((interest: User) => {
                  return (
                    <Tr
                      key={`interest ${interest.id}`}
                      _hover={{ bg: '#eaeaea' }}
                      cursor="pointer"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate(`/interest/${interest.id}`)
                      }}
                    >
                      <Td>
                        <Box display="flex" flexDirection="row" gap={10}>
                          <FaStar />
                          {interest.nick}
                        </Box>
                      </Td>
                      <Td>
                        <Box display="flex" flexDirection="row" gap={10}>
                          <Text>
                            {interest.diarys.length > 0
                              ? dayjs(interest.diarys[0].createdAt)
                                  .utc()
                                  .format('YYYY년 M월 D일')
                              : '최근 일기기록이 없습니다.'}
                          </Text>
                          <Text color="gray">
                            {interest.diarys.length > 0 &&
                              elapsedTime(
                                interest.diarys[0].createdAt.toString(),
                              )}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Box display="flex" flexDirection="row" gap={10}>
                          {interest.tests.length > 0 ? (
                            <>
                              <FaCircle
                                color={testResult(
                                  interest.tests[0].testCategory.name,
                                  interest.tests[0].score,
                                )}
                              />
                              {interest.tests[0].testCategory.name},
                              {interest.tests[0].score}
                              <Text color="gray">
                                {elapsedTime(
                                  interest.tests[0].createdAt.toString(),
                                )}
                              </Text>
                            </>
                          ) : (
                            <>
                              <FaCircle color="gray" />
                              최근 검사 기록이 없습니다.
                            </>
                          )}
                        </Box>
                      </Td>
                      <Td>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedId(interest.id)
                            notifyModal.onOpen()
                          }}
                        >
                          알림 보내기
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>
          <NotifyModal state={notifyModal} user={selectedId} />
        </TableContainer>
        <NotifyModal state={notifyModal} user={selectedId} />
        <Pagination
          current={page}
          total={total}
          perPage={10}
          totalPages={totalPages}
          setPage={(p) => {
            setPage(p)
          }}
        />
      </Box>
    </Layout>
  )
}
