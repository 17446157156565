import { Section } from 'component'
import dayjs from 'dayjs'
import { useState } from 'react'
import Calendar from 'react-calendar'
import { useQuery } from 'react-query'
import { DiaryService } from 'service'
import './calendar.css'

export function Calender() {
  const [year, setYear] = useState(dayjs().year())
  const [month, setMonth] = useState(dayjs().month() + 1)
  const [getDiary, setGetDiary] = useState(true)

  const getCalendarDiary = useQuery(
    ['getCalendarDiary', year, month],
    async () => {
      return await DiaryService.getCalendarDiary(
        year.toString(),
        month.toString(),
      )
    },
    {
      enabled: getDiary,
      onSuccess: (data) => {
        setGetDiary(false)
      },
    },
  )

  return (
    <Section title="마음일기 개수" sx={{ p: '10' }}>
      <Calendar
        className="react-calendar"
        calendarType="US"
        locale="ko"
        next2Label={null}
        prev2Label={null}
        showNeighboringMonth={false}
        formatDay={(locale, date) => dayjs(date).format('D')}
        onActiveStartDateChange={({ activeStartDate }) => {
          setYear(dayjs(activeStartDate).year())
          setMonth(dayjs(activeStartDate).month() + 1)
          setGetDiary(true)
        }}
        tileContent={({ date, view }) => {
          const dateInfo =
            !getCalendarDiary.isLoading &&
            getCalendarDiary.data.find(
              (x: { _count: number; date: string }) =>
                dayjs(x.date).format('YYYY-MM-DD') ===
                dayjs(date).format('YYYY-MM-DD'),
            )
          if (dateInfo) {
            return (
              <>
                <div className="dot">{dateInfo._count}</div>
              </>
            )
          } else {
            return (
              <>
                <div className="dot white"></div>
              </>
            )
          }
        }}
      />
    </Section>
  )
}
