import { Coupon, Meta } from 'model'
import { api } from '.'

type Result = {
  data: Coupon[]
  meta: Meta
}

class CouponService {
  async get({ limit, page = 1 }: { limit: number; page?: number }) {
    return await api.get<Result>(`/coupons?limit=${limit}&page=${page}`)
  }

  async post(input: Pick<Coupon, 'name' | 'period' | 'unit' | 'expiredAt'>) {
    return await api.post('/coupons', input)
  }

  async remove(id: number) {
    return await api.delete(`/coupons/${id}`)
  }
}
export default new CouponService()
