import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { InputBox, Layout, Section, ToastTheme, Pagination } from 'component'
import { PickerCalendarBtn } from 'component/notice/notifyModal/picker-calendar/Calendar'
import dayjs from 'dayjs'
import { Coupon } from 'model'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { CouponService } from 'service'

type Data = {
  message: string[]
  statusCode: number
}

export default function CouponScreen() {
  const toast = useToast()
  const modal = useDisclosure()
  const [couponlist, setCouponList] = useState<Coupon[]>([])
  const [coupon, setCoupon] = useState<boolean>(true)
  const [total, setTotal] = useState<number>(-1)
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPage] = useState<number>(1)
  const [name, setName] = useState<string>('')
  const [id, setId] = useState<number>(0)
  const [content, setContent] = useState<{
    unit: 'w' | 'M' | 'd'
    period: number
  }>({
    unit: 'w',
    period: 1,
  })

  const [expiredAt, setExpiredAt] = useState<string>('')
  const option = (unit: 'w' | 'M' | 'd' | undefined) => {
    if (unit === 'w') {
      return Array.from({ length: 52 }, (v, x) => x + 1)
    } else if (unit === 'M') {
      return Array.from({ length: 12 }, (v, x) => x + 1)
    } else {
      return Array.from({ length: 100 }, (v, x) => x + 1)
    }
  }

  useQuery(
    ['getCoupons', page],
    async () => await CouponService.get({ limit: 5, page }),
    {
      enabled: coupon,
      onSuccess: (data) => {
        setCouponList(data.data)
        setTotal(data.meta.total)
        setPage(data.meta.currentPage)
        setTotalPage(data.meta.totalPages)
        setCoupon(false)
      },
    },
  )
  const createCoupon = useMutation(
    ['create coupon'],
    async () =>
      await CouponService.post({
        name,
        unit: content.unit,
        period: content.period,
        expiredAt,
      }),
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '이용권이 생성되었습니다!',
            ...ToastTheme.success,
          })
          setCoupon(true)
        }
      },
      onError: (error: AxiosError) => {
        const message = (error.response?.data as Data).message
        toast({
          description: message,
          ...ToastTheme.error,
        })
      },
    },
  )

  const removeCoupon = useMutation(
    ['removeCoupon', id],
    async () => await CouponService.remove(id),
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '삭제되었습니다.',
            ...ToastTheme.success,
          })
          setCoupon(true)
        }
      },
      onError: () => {
        toast({
          description: '삭제를 실패하였습니다.',
          ...ToastTheme.error,
        })
      },
    },
  )
  const DeleteModal = () => {
    return (
      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>이용권 삭제</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              이용권을 삭제하면 해당 이용권을 사용하고 있는 사용자들이 더이상
              서비스를 이용하지 못합니다. <br />
              정말 삭제하시겠습니까?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              onClick={() => {
                modal.onClose()
              }}
            >
              취소
            </Button>
            <Button
              onClick={() => {
                removeCoupon.mutate()
                modal.onClose()
              }}
            >
              삭제
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Layout title="이용권 관리">
      <DeleteModal />
      <Section title="이용권 생성" sx={{ p: '10', mb: 6 }}>
        <Flex align="center" gap={4}>
          <Text>이용권 이름</Text>
          <InputBox label="" value={name} setValue={setName} />
        </Flex>
        <Flex align="center" gap={4} mt={2}>
          <Text>사용 기간</Text>
          <Flex gap={1}>
            <Select
              w="150px"
              value={content.unit}
              onChange={(e) => {
                if (
                  e.target.value === 'w' ||
                  e.target.value === 'M' ||
                  e.target.value === 'd'
                ) {
                  setContent({ ...content, unit: e.target.value })
                }
              }}
            >
              <option value="d">일</option>
              <option value="w">주</option>
              <option value="M">달</option>
            </Select>
            <Select
              w="150px"
              value={content.period}
              onChange={(e) => {
                setContent({ ...content, period: Number(e.target.value) })
              }}
            >
              {option(content.unit).map((i: number) => {
                return (
                  <option key={i} value={i}>
                    {i}
                  </option>
                )
              })}
            </Select>
          </Flex>
        </Flex>
        <Flex gap={4} align="center" mt={2}>
          <Text>유효 기간</Text>
          <Box
            as="p"
            h="24px"
            w="200px"
            borderBottomWidth="1px"
            borderColor="gray.500"
            textAlign="center"
          >
            {expiredAt !== '' ? dayjs(expiredAt).format('YYYY-M-D') : ''}
          </Box>
          <PickerCalendarBtn
            getDate={(d) => {
              setExpiredAt(d)
            }}
          />
        </Flex>
        <Button
          onClick={() => {
            createCoupon.mutate()
          }}
        >
          생성
        </Button>
      </Section>
      <Section title="이용권 목록" sx={{ p: 10 }}>
        <Table>
          <Thead>
            <Tr>
              <Td>이름</Td>
              <Td>사용 기간</Td>
              <Td>유효 기간</Td>
              <Td></Td>
            </Tr>
          </Thead>
          <Tbody>
            {couponlist.length === 0 && (
              <Tr>
                <Td>이용권을 등록해주세요.</Td>
              </Tr>
            )}
            {couponlist.length > 0 &&
              couponlist.map((coupon: Coupon) => {
                return (
                  <Tr key={`coupon ${coupon.id}`}>
                    <Td>{coupon.name}</Td>
                    <Td>{`${coupon.period} ${
                      coupon.unit === 'w'
                        ? '주'
                        : coupon.unit === 'M'
                        ? '개월'
                        : '일'
                    }`}</Td>
                    <Td>{dayjs(coupon.expiredAt).format('YYYY년 M월 D일')}</Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setId(coupon.id)
                          modal.onOpen()
                        }}
                      >
                        삭제
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
        <Pagination
          current={page}
          total={total}
          perPage={5}
          totalPages={totalPages}
          setPage={(p) => {
            setPage(p)
            setCoupon(true)
          }}
        />
      </Section>
    </Layout>
  )
}
