import { ResponseChoice } from 'model'
import { api } from '.'

class PromptTestService {
  async createResults({
    prompt,
    temperature,
    maxTokens,
  }: {
    prompt: string
    temperature: number
    maxTokens: number
  }) {
    return await api.post<ResponseChoice[]>('/prompt-test', {
      prompt,
      temperature,
      context: ' ',
      max_tokens: maxTokens,
    })
  }
}
export default new PromptTestService()
