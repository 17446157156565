import { Grid, GridItem } from '@chakra-ui/react'
import { Calender, Layout, Overview, RemainDiary } from 'component'
import { getCookie } from 'helpers'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { api } from 'service/base.service'

export default function HomeScreen() {
  const nagivate = useNavigate()
  const token = getCookie('accessToken')
  if (!token) {
    nagivate('/login')
  }
  const { data, isLoading } = useQuery(
    ['dashborad'],
    async () => await api.get('/diaries/dashboard'),
  )

  return (
    <Layout title="홈">
      <Grid gap={6} templateColumns="1fr 1fr">
        <GridItem colSpan={2}>
          {!isLoading && (
            <Overview
              new={data.new}
              waiting={data.waiting}
              concern={data.concern}
              like={data.like}
            />
          )}
        </GridItem>
        <Calender />
        <RemainDiary />
      </Grid>
    </Layout>
  )
}
