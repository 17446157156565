import { Box, Flex, IconButton, Input, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { UserService } from 'service'

export function InterestHeader({
  setSearch,
}: {
  setSearch?: (word: string) => void
}) {
  const { id } = useParams()
  const [total, setTotal] = useState(0)
  const [interest, setInterest] = useState(0)
  const [searchWord, setSearchWord] = useState('')

  const enterSearch = () => {
    if (setSearch) {
      setSearch(searchWord)
    }
  }

  useQuery(
    ['getInterest'],
    async () => {
      return await UserService.getInterestCount()
    },
    {
      onSuccess: (data) => {
        setInterest(data)
      },
    },
  )
  useQuery(
    ['getUserTotal'],
    async () => {
      return await UserService.getTotalCount()
    },
    {
      onSuccess: (data) => {
        setTotal(data)
      },
    },
  )

  return (
    <>
      <Flex align="center" justify="space-between">
        <Box>
          {id && (
            <IconButton
              bg="transparent"
              icon={<FaArrowLeft />}
              aria-label="back"
              onClick={() => {
                history.back()
              }}
            />
          )}
          <Text as="h4" fontWeight="bold" display="inline" mr={2}>
            관심 인원
          </Text>
          <Text as="span">
            <Text as="span" color="green">
              {interest}
            </Text>
            / {total}
          </Text>
        </Box>
        {setSearch && (
          <Input
            w="40%"
            placeholder="검색"
            focusBorderColor="darkblue"
            value={searchWord}
            onChange={(e) => {
              e.preventDefault()
              setSearchWord(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                enterSearch()
              }
            }}
            isDisabled={!setSearch}
          />
        )}
      </Flex>
    </>
  )
}
