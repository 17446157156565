import { NoticeCategory, NoticeSetting } from 'model'
import { api } from '.'

type NoticeInput = {
  concern: boolean
  category: NoticeCategory
  contents: string
  date: Date
}

class NoticeService {
  async sendNotices(input: NoticeInput) {
    return await api.post('/notifications', input)
  }

  async sendNotice(userId: number, input: Omit<NoticeInput, 'concern'>) {
    return await api.post(`/notifications/${userId}`, input)
  }

  async getNoticeSettings() {
    return await api.get<NoticeSetting[]>('/notifications/settings')
  }

  async updateNoticeSetting(
    id: number,
    input: Pick<
    NoticeSetting,
    | 'contents'
    | 'firstPeriod'
    | 'firstUnit'
    | 'secondContents'
    | 'secondPeriod'
    | 'secondUnit'
    | 'thirdContents'
    | 'thirdPeriod'
    | 'thirdUnit'
    | 'hour'
    | 'minute'
    >,
  ) {
    return await api.patch<boolean>(`/notifications/settings/${id}`, input)
  }

  async activateNoticeSetting(id: number) {
    return await api.patch<boolean>(`/notifications/settings/${id}/active`)
  }
}
export default new NoticeService()
