import { Button, VStack } from '@chakra-ui/react'
import { Section } from 'component/common'
import { Diary } from 'model'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DiaryService } from 'service'
import { DiaryItem } from './DiaryItem'

export function RemainDiary() {
  const navigate = useNavigate()

  const { isLoading, data, refetch } = useQuery(
    ['getRemainDiarys'],
    async () => await DiaryService.getWaiting(),
  )

  useEffect(() => {
    refetch()
  }, [data])

  return (
    <Section
      title="답변 대기 일기"
      sx={{ p: '10' }}
      rightComponent={
        <Button
          size="sm"
          onClick={() => {
            navigate('/diary')
          }}
        >
          더보기
        </Button>
      }
    >
      <VStack gap={1} align="stretch">
        {!isLoading &&
          data &&
          data?.map((item: Diary) => {
            return <DiaryItem key={item.id} item={item} />
          })}
      </VStack>
    </Section>
  )
}
