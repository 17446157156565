import { Divider, Flex, Select, Text, Textarea, VStack } from '@chakra-ui/react'
import { NoticeSetting } from 'model'
import { ReactNode, useEffect, useState } from 'react'

export function Row({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  return (
    <>
      <Flex align="center" p={2}>
        <Text as="b" p="2" w="150px">
          {title}
        </Text>
        {children}
      </Flex>
      <Divider my={2} />
    </>
  )
}

export type DetailConditionType = {
  contents: string
  unit: 'w' | 'M'
  period: number
}

function DetailConditionElement({
  i,
  content,
  setContent,
}: {
  i: number
  content: DetailConditionType
  setContent: (arg: DetailConditionType) => void
}) {
  const option = (unit: 'w' | 'M' | undefined) => {
    if (unit === 'w') {
      return Array.from({ length: 52 }, (v, x) => x + 1)
    } else {
      return Array.from({ length: 12 }, (v, x) => x + 1)
    }
  }

  return (
    <VStack gap={1} align="stretch">
      <Text as="b" w="150px">
        {i}차 주기
      </Text>
      <Flex gap={1}>
        <Select
          w="150px"
          value={content.unit}
          onChange={(e) => {
            if (e.target.value === 'w' || e.target.value === 'M') {
              setContent({ ...content, unit: e.target.value })
            }
          }}
        >
          <option value="w">주</option>
          <option value="M">달</option>
        </Select>
        <Select
          w="150px"
          value={content.period}
          onChange={(e) => {
            setContent({ ...content, period: Number(e.target.value) })
          }}
        >
          {option(content.unit).map((i: number) => {
            return (
              <option key={i} value={i}>
                {i}
              </option>
            )
          })}
        </Select>
      </Flex>
      <Text as="b" w="150px">
        {i}차 내용
      </Text>
      <Textarea
        w="100%"
        value={content.contents}
        onChange={(e) => {
          setContent({ ...content, contents: e.target.value })
        }}
      />
    </VStack>
  )
}

export function DetailCondition({
  getData,
  data,
}: {
  getData: (arg: DetailConditionType[]) => void
  data: NoticeSetting
}) {
  const [first, setFirst] = useState<DetailConditionType>({
    unit: data.firstUnit ?? 'w',
    period: data.firstPeriod ?? 1,
    contents: data.contents ?? '',
  })
  const [second, setSecond] = useState<DetailConditionType>({
    unit: data.secondUnit ?? 'w',
    period: data.secondPeriod ?? 2,
    contents: data.secondContents ?? '',
  })
  const [third, setThird] = useState<DetailConditionType>({
    unit: data.thirdUnit ?? 'M',
    period: data.thirdPeriod ?? 1,
    contents: data.thirdContents ?? '',
  })

  useEffect(() => {
    getData([first, second, third])
  }, [first, second, third])

  return (
    <Row title="세부조건">
      <VStack w="80%" align="stretch">
        <DetailConditionElement i={1} content={first} setContent={setFirst} />
        <DetailConditionElement i={2} content={second} setContent={setSecond} />
        <DetailConditionElement i={3} content={third} setContent={setThird} />
      </VStack>
    </Row>
  )
}
