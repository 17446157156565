import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { Sidebar, ToastTheme } from 'component'
import { getCookie } from 'helpers'
import { useEffect } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import {
  HomeScreen,
  DiaryScreen,
  DiaryDetailScreen,
  InterestScreen,
  SettingScreen,
  LoginScreen,
  InterestDetailScreen,
  NoticeScreen,
  NoticeManageScreen,
  LocationScreen,
} from 'screen'
import { CouponScreen } from 'screen/coupon'
import { TestScreen } from 'screen/test'

function BasicLayout() {
  const accesstoken = getCookie('accessToken')
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    if (accesstoken) {
      axios
        .get('https://server.doctorpresso.com/admin/auth', {
          headers: { Authorization: `Bearer ${accesstoken}` },
        })
        .then(function (response) {})
        .catch(function (err) {
          if (err.response.status === 401) {
            toast({
              description: '로그인이 필요한 서비스 입니다',
              ...ToastTheme.error,
            })
            navigate('/login')
          }
        })
      return
    }

    toast({
      description: '로그인이 필요한 서비스 입니다',
      ...ToastTheme.error,
    })
    navigate('/login')
  }, [accesstoken])

  return (
    <>
      <Sidebar />
      <main style={{ overflow: 'auto', width: '100%' }}>
        <Outlet />
      </main>
    </>
  )
}
function LoginLayout() {
  return <Outlet />
}

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route path="" element={<HomeScreen />} />
        <Route path="diary" element={<DiaryScreen />} />
        <Route path="diary/:id" element={<DiaryDetailScreen />} />
        <Route path="interest" element={<InterestScreen />} />
        <Route path="interest/:id" element={<InterestDetailScreen />} />
        <Route path="notice" element={<NoticeScreen />} />
        <Route path="notice/:title" element={<NoticeManageScreen />} />
        <Route path="setting" element={<SettingScreen />} />
        <Route path="test" element={<TestScreen />} />
        <Route path="coupon" element={<CouponScreen />} />
        <Route path="location" element={<LocationScreen />} />
      </Route>
      <Route path="/" element={<LoginLayout />}>
        <Route path="login" element={<LoginScreen />} />
      </Route>
    </Routes>
  )
}
