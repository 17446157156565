import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Button,
  useToast,
} from '@chakra-ui/react'
import { ToastTheme } from 'component/common'
import { NoticeCategory } from 'model'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { NoticeService } from 'service'
import { CreateNotice } from '../CreateNotice'

type DisclosureObject = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  getDisclosureProps: () => void
  getButtonProps: () => void
}

export type Input = {
  category: NoticeCategory
  contents: string
  date: Date
}

export function NotifyModal({
  state,
  user,
}: {
  state: DisclosureObject
  user: number | 'total' | 'interest'
}) {
  const toast = useToast()
  const defaultValue = {
    category: 'diary' as NoticeCategory,
    contents: '',
    date: new Date(),
  }
  const [input, setInput] = useState<Input>(defaultValue)

  const sendNotification = useMutation(
    ['sendNotification', user],
    async ({
      category,
      contents,
      date,
    }: {
      category: NoticeCategory
      contents: string
      date: Date
    }) => {
      if (typeof user === 'number') {
        const id = user
        return await NoticeService.sendNotice(id, {
          category,
          contents,
          date,
        })
      }
    },
    {
      onSuccess: () => {
        toast({ description: '알림을 전송하였습니다', ...ToastTheme.success })
      },
      onError: () => {
        toast({
          description: '알림 전송을 실패하였습니다',
          ...ToastTheme.error,
        })
      },
    },
  )

  return (
    <Modal isOpen={state.isOpen} onClose={state.onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>알림보내기</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateNotice setInput={setInput} />
        </ModalBody>
        <ModalFooter>
          <Button mr={2}>취소</Button>
          <Button
            onClick={() => {
              sendNotification.mutate(input)
            }}
          >
            확인
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
