import { Flex } from '@chakra-ui/react'
import { Section } from 'component'
import { Status } from './Status'

interface OverviewProp {
  new: number
  waiting: number
  concern: number
  like: number
}

export function Overview(prop: OverviewProp) {
  return (
    <Section>
      <Flex p={10} justify="space-around">
        <Status name="새로운 일기" count={prop.new} />
        <Status name="답변 대기 일기" count={prop.waiting} />
        <Status name="관심인원의 일기" count={prop.concern} />
        <Status name="좋아요" count={prop.like} />
      </Flex>
    </Section>
  )
}
