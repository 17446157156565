/* eslint-disable @typescript-eslint/quotes */
import {
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Textarea,
  VStack,
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react'
import { Section } from 'component'
import { AutoAnswerParam, Prompt } from 'model'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { PromptService } from 'service'
import { PromptSettingModal, NameInput } from './PromptSettingModal'

export function DiaryPrompt({
  setParam,
}: {
  setParam: (param: AutoAnswerParam) => void
}) {
  const [prompt, setPrompt] = useState<string>('')
  const [temperature, setTemperature] = useState<number>(0)
  const [maxTokens, setMaxTokens] = useState<number>(0)
  const [promptList, setPromptList] = useState<Prompt[]>([])
  const [refetchList, setRefetchList] = useState<boolean>(true)

  const [name, setName] = useState<string>('')
  const [id, setId] = useState<number>(0)
  const [reId, setReId] = useState<boolean>(false)
  const [defaultSet, setDefaultSet] = useState<boolean>(true)

  const [total, setTotal] = useState<number>(-1)
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPage] = useState<number>(1)

  const settingModal = useDisclosure()
  const nameModal = useDisclosure()

  const createAnswer = () => {
    const params: AutoAnswerParam = { prompt, temperature, maxTokens }
    setParam(params)
  }

  const CreateSettingModal = () => {
    return (
      <Modal
        onClose={nameModal.onClose}
        isOpen={nameModal.isOpen}
        key="setting modal"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>프롬프트 설정 이름</ModalHeader>
          <ModalBody>
            <NameInput
              nameModal={nameModal}
              setContent={() => {
                const content = {
                  temperature,
                  prompt,
                  maxTokens,
                }
                return content
              }}
              refetch={(arg: boolean) => {
                setRefetchList(arg)
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  useQuery(
    ['get prompt setting', page],
    async () => {
      return await PromptService.getAll({ limit: 5, page })
    },
    {
      enabled: refetchList,
      onSuccess: (data) => {
        setPromptList(data.data)
        setRefetchList(false)
        setTotal(data.meta.total)
        setPage(data.meta.currentPage)
        setTotalPage(data.meta.totalPages)
      },
    },
  )

  useQuery(
    ['get prompt setting', page],
    async () => {
      return await PromptService.getAll({ limit: 5, page })
    },
    {
      enabled: refetchList,
      onSuccess: (data) => {
        setPromptList(data.data)
        setRefetchList(false)
        setTotal(data.meta.total)
        setPage(data.meta.currentPage)
        setTotalPage(data.meta.totalPages)
      },
    },
  )

  useQuery(['getDefaultPrompt'], async () => await PromptService.getDefault(), {
    enabled: defaultSet,
    onSuccess: (data) => {
      setName(data ? data.name : '기본 프롬프트')
      setPrompt(
        data
          ? data.prompt
          : '당신은 심리 상담사입니다. 다음 일기를 읽고 적절한 반응을 하십시오.',
      )
      setTemperature(data ? data.temperature : 1)
      setMaxTokens(data ? data.maxTokens : 300)
      setDefaultSet(false)
    },
  })

  useQuery(['getPromptSetting', id], async () => await PromptService.get(id), {
    enabled: reId,
    onSuccess: (data) => {
      setName(data.name)
      setPrompt(data.prompt)
      setTemperature(data.temperature)
      setMaxTokens(data.maxTokens)
      setReId(false)
    },
  })

  return (
    <Section title="프롬프트 입력" sx={{ p: '6' }}>
      <CreateSettingModal />
      <PromptSettingModal
        state={settingModal}
        page={page}
        total={total}
        totalPages={totalPages}
        setting={promptList}
        setPage={setPage}
        refetch={(arg: boolean) => {
          setRefetchList(arg)
        }}
        setId={setId}
        setReId={setReId}
        setDefaultSet={setDefaultSet}
      />
      <Flex justify="space-between" mb={4}>
        <Flex align="center" gap={2}>
          <Text as="b">{name}</Text>
          <Text
            cursor="pointer"
            onClick={() => {
              settingModal.onOpen()
            }}
          >
            프롬프트 관리
          </Text>
        </Flex>
        <Button borderRadius="full" size="sm" onClick={nameModal.onOpen}>
          +
        </Button>
      </Flex>
      <VStack gap={4} display="inline-block" w="100%">
        <Textarea
          value={prompt}
          onChange={(e) => {
            setPrompt(e.target.value)
          }}
          resize="none"
        />
        <Box>
          <Text as="b">생성 자유도(1~10)</Text>
          <NumberInput
            value={temperature}
            onChange={(_, v) => {
              setTemperature(v)
            }}
            min={1}
            max={10}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box>
          <Text as="b">생성 최대 길이 (10~4000)</Text>
          <NumberInput
            value={maxTokens}
            onChange={(_, v) => {
              setMaxTokens(v)
            }}
            min={10}
            max={4000}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <Box>
          <Text as="b">반복 실행 횟수</Text>
          <NumberInput value={1}>
            <NumberInputField />
          </NumberInput>
        </Box>
        <Button
          onClick={() => {
            createAnswer()
          }}
        >
          텍스트 생성
        </Button>
      </VStack>
    </Section>
  )
}
