import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ToastTheme } from 'component/common'
import { Prompt } from 'model'
import { Dispatch, SetStateAction, useState } from 'react'
import { FaCheck, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useMutation } from 'react-query'
import { PromptService } from 'service'

type DisclosureObject = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  getDisclosureProps: () => void
  getButtonProps: () => void
}

export function OptionBtn({
  state,
  getEdit,
  setContent,
  refetch,
  setId,
  setReId,
  setDefaultSet,
}: {
  state: DisclosureObject
  getEdit: (arg: boolean) => void
  setContent: () => Prompt
  refetch: (arg: boolean) => void
  setId: Dispatch<SetStateAction<number>>
  setReId: Dispatch<SetStateAction<boolean>>
  setDefaultSet: Dispatch<SetStateAction<boolean>>
}) {
  const [edit, setEdit] = useState<boolean>(false)
  const deleteModal = useDisclosure()
  const toast = useToast()

  const setDefault = useMutation(
    ['set prompt default'],
    async () => {
      const content: Prompt = setContent()
      return await PromptService.setDefault(content.id)
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '기본 설정으로 변경되었습니다.',
            ...ToastTheme.success,
          })
          refetch(true)
        }
      },
    },
  )

  const editContent = useMutation(
    ['edit prompt setting'],
    async () => {
      const content: Prompt = setContent()
      return await PromptService.edit(content.id, {
        name: content.name,
        maxTokens: content.maxTokens,
        temperature: content.temperature,
        prompt: content.prompt,
      })
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '설정이 변경되었습니다.',
            ...ToastTheme.success,
          })
          refetch(true)
        }
      },
    },
  )

  const deleteContent = useMutation(
    ['delete prompt setting'],
    async () => {
      const content: Prompt = setContent()
      return await PromptService.delete(content.id)
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '프롬프트 설정 항목이 삭제되었습니다.',
            ...ToastTheme.success,
          })
          refetch(true)
          setDefaultSet(true)
        }
      },
    },
  )

  const select = () => {
    const content: Prompt = setContent()
    setId(content.id)
    setReId(true)
    state.onClose()
  }

  const DeleteModal = () => {
    return (
      <Modal
        onClose={deleteModal.onClose}
        isOpen={deleteModal.isOpen}
        key="prompt setting delete modal"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>프롬프트 설정 삭제</ModalHeader>
          <ModalBody>
            <Text>프롬프트 설정을 삭제하시겠습니까?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              mr={2}
              onClick={() => {
                deleteContent.mutateAsync()
                deleteModal.onClose()
              }}
            >
              삭제
            </Button>
            <Button size="sm" onClick={deleteModal.onClose}>
              취소
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Flex justify="flex-end" my={2} gap={2}>
      <DeleteModal />
      {edit ? (
        <Button
          size="sm"
          borderRadius="full"
          onClick={() => {
            setEdit(false)
            getEdit(false)
            editContent.mutate()
          }}
        >
          <FaCheck />
          저장
        </Button>
      ) : (
        <>
          <Button
            size="sm"
            borderRadius="full"
            onClick={() => {
              setDefault.mutateAsync()
            }}
          >
            기본으로 설정
          </Button>
          <Button size="sm" borderRadius="full" onClick={select}>
            선택
          </Button>
          <Button
            size="sm"
            borderRadius="full"
            onClick={() => {
              setEdit(true)
              getEdit(true)
            }}
          >
            <FaEdit /> 편집
          </Button>
          <Button size="sm" borderRadius="full" onClick={deleteModal.onOpen}>
            <FaTrashAlt /> 삭제
          </Button>
        </>
      )}
    </Flex>
  )
}
